import React, {useState} from 'react'
import { Link } from 'react-router-dom';

const EmployeeSidebar = ({ sidebarToggle }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/'; // Redirect after logout
  };

  return (
    
    <div className={`fixed top-0 left-0 h-full w-64 bg-custom-blue px-4 py-2 transition-transform transform ${
        sidebarToggle ? "translate-x-0" : "-translate-x-full"
      } md:translate-x-0 md:block md:w-64`}
    >
      <hr className='my-2' />
      <ul className='space-y-2'>
       <li><Link to='/employee-home' className='link'>Dashboard</Link></li> 
       
        <li><Link to='/employee_profile' className='link'>Account</Link></li>
        <li><Link to='/add_jobs' className='link'>Jobs</Link></li>
        <li><Link to='/employee_view_jobs' className='link'>ViewJobs</Link></li>
        <button onClick={() => setIsModalOpen(true)} className='logout-btn'>
            Logout
          </button>


      </ul>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg font-semibold">Confirm Logout</h2>
            <p>Are you sure you want to log out?</p>
            <div className="flex justify-end mt-4">
              <button className="mr-2 bg-gray-300 py-2 px-4 rounded" onClick={() => setIsModalOpen(false)}>
                Cancel
              </button>
              <button className="bg-red-500 text-white py-2 px-4 rounded" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
     <style>{`
        .logout-btn {
          background-color: #e74c3c; /* Red background for logout */
          color: white;
          padding: 10px 15px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s;
          width: 100%; /* Full width */
          text-align: left; /* Align text to left */
        }

        .logout-btn:hover {
          background-color: #c0392b; /* Darker red on hover */
        }

        .link {
          color: white; /* Ensure links are white */
          text-decoration: none; /* Remove underline */
          padding: 10px 15px; /* Padding for links */
          display: block; /* Block display for better click area */
          border-radius: 5px; /* Round edges */
          transition: background-color 0.3s; /* Smooth transition */
        }

        .link:hover {
          background-color: white;
          color:bg-custom-blue /* Lighten on hover */
        }
      `}</style>
    </div>
  );
};


export default EmployeeSidebar
