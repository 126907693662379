import React, { useEffect, useState } from 'react';
import AdminSidebar from './AdminSidebar';
import Navbarone from './Navbarone';
import { FaBars } from 'react-icons/fa';
import axios from 'axios';
import config from '../../Functions/config';

const AdminViewAllJob = ({ sidebarToggle, setSidebarToggle }) => {
  const baseurl = config.baseurl;
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${baseurl}/view_all_job`);
        setJobs(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  const getStatusClass = (status) => {
    switch (status) {
      case 'APPROVED':
        return 'text-green-500';
      case 'REJECTED':
        return 'text-red-500';
      case 'PENDING':
        return 'text-yellow-500';
      default:
        return 'text-gray-500';
    }
  };

  const filteredJobs = searchTerm
    ? jobs.filter(job =>
        job.company && job.company.toLowerCase().includes(searchTerm.toLowerCase()) && job.status === 'APPROVED'
      )
    : jobs.filter(job => job.status === 'APPROVED');

  return (
    <div className="flex h-screen">
      <button
        className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
        onClick={() => setSidebarToggle(!sidebarToggle)}
      >
        <FaBars className="w-6 h-6" />
      </button>

      <AdminSidebar sidebarToggle={sidebarToggle} />

      <div className={`flex-1 transition-all duration-300 ease-in-out ml-0 md:ml-64`}>
        <Navbarone sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

        <main className="pt-4">
          <div className='container-fluid'>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Search by company name"
                className="border rounded-lg p-2 w-full"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {loading && <p className="text-center text-lg">Loading jobs...</p>}
            {error && <p className="text-red-500 text-center">Error fetching jobs: {error}</p>}
            {!loading && !error && (
              <ul className="flex flex-wrap space-y-4">
                <h2 className="font-bold text-xl w-full">All Approved Jobs</h2>
                {filteredJobs.length > 0 ? (
                  filteredJobs.map(job => (
                    <li key={job.id} className="flex flex-col justify-between items-start border rounded-lg p-4 bg-white shadow hover:shadow-lg transition-shadow w-full md:w-1/2">
                      {job.image && (
                          <img
                            src={`${baseurl}${job.image}`}
                            alt={job.designation}
                            className="w-80 h-30 rounded-md my-2"
                          />
                        )}
                      <div className="flex-1">
                        <h3 className="text-xl font-bold">{job.designation}</h3>
                        <p className="text-gray-600 italic">{job.company}</p>
                        <p className="text-gray-600">Posting Date: {job.posting_date}</p>
                        <p className="text-gray-600">Last Date: {job.last_date}</p>
                        <p className="text-gray-600">Description: {job.description}</p>

                        <p className="text-gray-700">Requirements: {job.requirements}</p>
                        <p className={`font-semibold ${getStatusClass(job.status)}`}>
                          Status: {job.status}
                        </p>
                      </div>
                      <p className={`text-blue-800 ml-4 font-semibold ${job.applicant_count > 0 ? 'bg-blue-100 rounded-full px-2' : 'bg-gray-100'}`}>
                        Applicants: {job.applicant_count}
                      </p>
                    </li>
                  ))
                ) : (
                  <p className="text-center w-full">No approved jobs found for "{searchTerm}"</p>
                )}
              </ul>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminViewAllJob;
