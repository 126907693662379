import React, { useEffect, useState } from 'react';
import AdminSidebar from './AdminSidebar';
import Navbarone from './Navbarone';
import { FaBars } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../Functions/config';


const AdminViewProfile = ({ sidebarToggle, setSidebarToggle }) => {
  const baseurl=config.baseurl
  const [adminData, setAdminData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showResetForm, setShowResetForm] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdminProfile = async () => {
      try {
        const response = await axios.get(`${baseurl}/admin_profile_view/`, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`
          }
        });
        setAdminData(response.data);
      } catch (err) {
        setError(err.response?.data?.error || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchAdminProfile();
  }, []);

  const handleEdit = () => {
    navigate('/edit_admin_profile');
    console.log("Edit profile clicked");
  };

  const handleResetPassword = async () => {
    const hasAlphabet = /[a-zA-Z]/.test(newPassword);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
    const hasNumber=/[0-9]/.test(newPassword)
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters long.");
      return;
    }
    if (!hasAlphabet) {
      setError("Password must contain at least one letter.");
      return;
    }
    if (!hasSpecialChar) {
      setError("Password must contain at least one special character.");
      return;
    }
    if(!hasNumber){
      setError("Password must contain atleast one number")
      return;
    }

    try {
      await axios.post(`${baseurl}/reset_password/`, { password: newPassword }, {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      });
      alert("Password reset successfully!");
      navigate('./login')
      setShowResetForm(false);
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred while resetting the password.');
    }
  };

  return (
    <div className="flex h-screen">
      <button
        className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
        onClick={() => setSidebarToggle(!sidebarToggle)}
      >
        <FaBars className="w-6 h-6" />
      </button>

      <AdminSidebar sidebarToggle={sidebarToggle} />

      <div className={`flex-1 transition-all duration-300 ease-in-out ml-0 md:ml-64`}>
        <Navbarone sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

        <main className="p-4">
          {loading && <div>Loading...</div>}
          {error && <div className="text-red-500">Error: {error}</div>}
          {adminData && (
            <div className="bg-white shadow-md rounded-lg p-6">
              <h1 className="text-xl font-bold mb-4">Admin Profile</h1>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex justify-between bg-gray-100 p-3 rounded">
                  <span className="font-semibold">Name:</span>
                  <span>{adminData.username}</span>
                </div>
                <div className="flex justify-between bg-gray-100 p-3 rounded">
                  <span className="font-semibold">Email:</span>
                  <span>{adminData.email}</span>
                </div>
                <div className="mt-6 flex space-x-4">
                  <button 
                    onClick={handleEdit} 
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  >
                    Edit Profile
                  </button>
                  <button 
                    onClick={() => setShowResetForm(true)} // Show reset form
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Reset Password Form */}
          {showResetForm && (
            <div className="bg-white shadow-md rounded-lg p-6 mt-4">
              <h2 className="text-xl font-bold mb-4">Reset Password</h2>
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => {setNewPassword(e.target.value); setError(null);}}
                className="border p-2 rounded w-full mb-4"
              />
              <input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => {setConfirmPassword(e.target.value); setError(null);}}
                className="border p-2 rounded w-full mb-4"
                
              />
              <button 
                onClick={handleResetPassword}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                Submit
              </button>
              <button 
                onClick={() => setShowResetForm(false)} // Close the form
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 ml-2"
              >
                Cancel
              </button>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default AdminViewProfile;
