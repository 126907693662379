// import React,{useState} from 'react'
// import { Link } from 'react-router-dom'
// import "./Navbar.css";

// const Navbar = () => {
//   const [menuOpen,setMenuOpen] = useState(false)
//   return (
//     <nav>
//       <Link to='/' className='title'>JobPortal</Link>
//       <div className='menu' onClick={() =>{
//         setMenuOpen(!menuOpen);
//       }}>
//         <span></span>
//         <span></span>
//         <span></span>

//       </div>
//       <ul className={menuOpen ? "open" : ""}>
//        <li><Link to='/' className='link'>Home</Link></li> 
//         <li><Link to='/login ' className='link'>Login</Link></li>
//        <li> <Link to='/register' className='link'>Seeker Register</Link></li>
//        <li> <Link to='/eregister' className='link'>Employee Register</Link></li>
//       </ul>
//     </nav>
//   )
// }

// export default Navbar
import React, { useState } from 'react';
import { Link } from 'react-router-dom'

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className='homenav'>
            <a href="#" className="title">JobPortal</a>
            <div className="menu" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <ul className={isOpen ? "open" : ""}>
       <li><Link to='/' className='link'>Home</Link></li> 
        <li><Link to='/login ' className='link'>Login</Link></li>
       <li> <Link to='/register' className='link'>Seeker Register</Link></li>
        <li> <Link to='/eregister' className='link'>Employee Register</Link></li>  
             </ul>
        </nav>
    );
};

export default Navbar;
