import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaBars } from 'react-icons/fa';
import EmployeeSidebar from './EmployeeSidebar';
import Navbarthree from './Navbarthree';
import config from '../../Functions/config';

const JobApplicants = ({ sidebarToggle, setSidebarToggle }) => {
    const baseurl = config.baseurl;
    const { jobId } = useParams();
    const [applicants, setApplicants] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchApplicants = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${baseurl}/job/applicants/${jobId}`, {
                    headers: { 'Authorization': `Token ${token}` },
                });
                setApplicants(response.data);
            } catch (error) {
                console.error('Error fetching applicants:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchApplicants();
    }, [jobId]);

    const handleStatusUpdate = async (applicantId, status) => {
        const token = localStorage.getItem('token');
        try {
            await axios.put(`${baseurl}/update_applicant/${applicantId}/`, { status }, {
                headers: { 'Authorization': `Token ${token}` },
            });
            const updatedApplicants = applicants.map(applicant => 
                applicant.id === applicantId ? { ...applicant, applied_status: status } : applicant
            );
            setApplicants(updatedApplicants);
        } catch (error) {
            console.error('Error updating applicant status:', error);
        }
    };

    const getImageUrl = (url) => {
        if (!url) return 'default-profile-picture-url';
        return url.startsWith('http') ? url : `${baseurl}${url}`;
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="flex h-screen">
            <button
                className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
                onClick={() => setSidebarToggle(!sidebarToggle)}
            >
                <FaBars className="w-6 h-6" />
            </button>

            <EmployeeSidebar sidebarToggle={sidebarToggle} />

            <div className={`flex-1 transition-all duration-300 ease-in-out ${sidebarToggle ? 'ml-64' : 'ml-0'} md:ml-64`}>
                <Navbarthree sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

                <main className="p-4">
                    <h2 className="text-2xl font-bold mb-4">View Applicants</h2>
                    {applicants.length === 0 ? (
                        <p>No applicants for this job.</p>
                    ) : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
                            {applicants.map(applicant => (
                                <div key={applicant.id} className="border rounded-lg bg-white shadow-sm p-4 flex flex-col">
                                    <img src={getImageUrl(applicant.seeker.profile_picture)} alt="Profile" className="w-16 h-16 rounded-full border-2 border-gray-300 mb-2" />
                                    
                                    <div className="flex-grow">
                                        <h3 className="text-lg font-semibold">
                                            {applicant.seeker.name}
                                            {applicant.seeker.user_status === 2 && <span className="ml-1 text-green-500">✔️</span>}
                                        </h3>
                                        <p className="text-gray-600 text-sm">{applicant.seeker.user.email}</p>
                                        <p className="text-sm"><strong>Address:</strong> {applicant.seeker.address}</p>
                                        <p className="text-sm"><strong>Qualification:</strong> {applicant.seeker.education_details}</p>
                                        <p className="text-sm"><strong>Status:</strong> {applicant.applied_status}</p>
                                        <p className="text-sm"><strong>Applied On:</strong> {new Date(applicant.applied_on).toLocaleDateString()}</p>
                                    </div>

                                    <div className="flex justify-between mt-2">
                                        {applicant.applied_status === 'ACCEPTED' ? (
                                            <span className="text-green-600">Accepted</span>
                                        ) : applicant.applied_status === 'REJECTED' ? (
                                            <span className="text-red-600">Rejected</span>
                                        ) : (
                                            <>
                                                <button onClick={() => handleStatusUpdate(applicant.id, 'ACCEPTED')} className="bg-green-500 text-white py-1 px-2 rounded hover:bg-green-600">Accept</button>
                                                <button onClick={() => handleStatusUpdate(applicant.id, 'REJECTED')} className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600">Reject</button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </main>
            </div>
        </div>
    );
};

export default JobApplicants;
