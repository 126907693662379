import React, { useEffect, useState } from 'react';
import AdminSidebar from './AdminSidebar';
import Navbarone from './Navbarone';
import { FaBars } from 'react-icons/fa';
import axios from 'axios';
import config from '../../Functions/config';

const AdminViewSeeker = ({ sidebarToggle, setSidebarToggle }) => {
  const baseurl = config.baseurl;
  const [seekers, setSeekers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    const fetchSeekers = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/seeker_list/`, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          },
        });
        setSeekers(response.data);
      } catch (err) {
        setError(err.response?.data?.error || 'An error occurred while fetching seekers.');
      } finally {
        setLoading(false);
      }
    };

    fetchSeekers();
  }, []);

  const handleVerify = async (seekerId) => {
    try {
      await axios.post(`${baseurl}/api/seeker_verify/${seekerId}/`, {}, {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        },
      });

      setSeekers(prev => prev.map(seeker =>
        seeker.id === seekerId ? { ...seeker, user_status: 2 } : seeker
      ));

      setModalMessage('Seeker verified successfully!');
      setIsModalOpen(true);
    } catch (err) {
      console.error('Error verifying seeker:', err);
    }
  };

  const handleBlock = async (seekerId) => {
    try {
      await axios.delete(`${baseurl}/api/seeker_block/${seekerId}/`, {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        },
      });
      setSeekers(prev => prev.filter(seeker => seeker.id !== seekerId));
      setModalMessage('Seeker blocked successfully!');
      setIsModalOpen(true);
    } catch (err) {
      console.error('Error blocking seeker:', err);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalMessage('');
  };

  return (
    <div className="flex h-screen">
      <button
        className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
        onClick={() => setSidebarToggle(!sidebarToggle)}
      >
        <FaBars className="w-6 h-6" />
      </button>

      <AdminSidebar sidebarToggle={sidebarToggle} />

      <div className={`flex-1 transition-all duration-300 ease-in-out ml-0 md:ml-64`}>
        <Navbarone sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

        <main className="p-4">
          {loading && <div>Loading seekers...</div>}
          {error && <div className="text-red-500">Error: {error}</div>}
          {seekers.length > 0 ? (
            <div className="bg-white shadow-md rounded-lg p-6">
              <h1 className="text-xl font-bold mb-4">Job Seekers</h1>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {seekers.map(seeker => (
                  <SeekerCard key={seeker.id} seeker={seeker} onVerify={handleVerify} onBlock={handleBlock} />
                ))}
              </div>
            </div>
          ) : (
            !loading && <div>No seekers found.</div>
          )}
        </main>
      </div>

      {isModalOpen && (
        <Modal message={modalMessage} onClose={closeModal} />
      )}
    </div>
  );
};

const SeekerCard = ({ seeker, onVerify, onBlock }) => {
  const imageUrl = seeker.profile_picture ? `${config.baseurl}${seeker.profile_picture}` : null;

  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow">
      {imageUrl ? (
        <img 
          src={imageUrl} 
          alt={`${seeker.name}'s profile`} 
          className="w-24 h-24 rounded-full mb-2" 
        />
      ) : (
        <div className="w-24 h-24 rounded-full bg-gray-300 flex items-center justify-center mb-2">
          <span className="text-gray-500">No Image</span>
        </div>
      )}
      <h2 className="font-semibold">{seeker.name}</h2>
      <p>{seeker.user.email}</p>
      <p><strong>Username:</strong> {seeker.user.username}</p>
      <p><strong>Date of Birth:</strong> {new Date(seeker.date_of_birth).toLocaleDateString() || 'N/A'}</p>
      <p><strong>Mobile Number:</strong> {seeker.mobile_number || 'N/A'}</p>
      <p><strong>Education:</strong> {seeker.education_details || 'N/A'}</p>
      <p><strong>Address:</strong> {seeker.address || 'N/A'}</p>
      <div className="mt-2">
        {seeker.user_status === 2 ? (
          <span className="text-green-500 font-bold">Verified</span>
        ) : (
          <button 
            onClick={() => onVerify(seeker.user.id)} 
            className="bg-green-500 text-white px-4 py-2 rounded mr-2"
          >
            Verify
          </button>
        )}
        <button 
          onClick={() => onBlock(seeker.user.id)} 
          className="bg-red-500 text-white px-4 py-2 rounded"
        >
          Block
        </button>
      </div>
    </div>
  );
};

const Modal = ({ message, onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <h2 className="text-lg font-bold mb-4">Success</h2>
      <p>{message}</p>
      <button 
        onClick={onClose} 
        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
      >
        OK
      </button>
    </div>
  </div>
);

export default AdminViewSeeker;
