import React, { useState, useEffect } from 'react';
import { FaBell, FaUserCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Style.css';
import config from '../../Functions/config';

const Navbarone = ({ sidebarToggle, setSidebarToggle }) => {
  const baseurl=config.baseurl
  const [applicantCount, setApplicantCount] = useState(0);

  useEffect(() => {
    const fetchApplicantCount = async () => {
      try {
        const response = await fetch(`${baseurl}/api/admin-applicant-count/`); // Adjust the endpoint as needed
        const data = await response.json();
        setApplicantCount(data.count); // Adjust according to your API response structure
      } catch (error) {
        console.error('Error fetching applicant count:', error);
      }
    };

    fetchApplicantCount();
  }, []);

  return (
    <nav className='flex items-center justify-between p-4 bg-custom-blue'>
      <div className='flex items-center text-xl'>
        <span className='text-white font-semibold'>JobPortal</span>
      </div>
      <div className='flex items-center gap-x-5'>
        <div className='relative md:w-65'></div>
        <div className='text-white relative'>
          <Link to="/adminnotifications">
            <FaBell className='w-6 h-6' />
            {applicantCount > 0 && (
              <span className='absolute -top-1 -right-1 bg-red-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs'>
                {applicantCount}
              </span>
            )}
          </Link>
        </div>
        {/* Uncomment this section if needed */}
        {/* <div className='relative'>
          <button className='text-white group'>
            <FaUserCircle className='w-6 h-6 mt-1' />
            <div className='z-10 hidden bg-custom-blue absolute rounded-lg shadow w-32 group-focus:block top-full right-0'>
              <ul className='py-2 text-sm text-gray-950'>
                <li>
                 <a href="/" className='text-white'>LogOut</a>
                </li>
              </ul>
            </div>
          </button>
        </div> */}
      </div>
    </nav>
  );
};

export default Navbarone;
