import { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import EmployeeSidebar from './EmployeeSidebar';
import Navbarthree from './Navbarthree';
import config from "../../Functions/config";

const EditJob = ({ sidebarToggle, setSidebarToggle }) => {
    const baseurl = config.baseurl;
    const { jobId } = useParams();
    const navigate = useNavigate();
    const [job, setJob] = useState({
        designation: '',
        description: '',
        last_date: '',
        requirements: '',
        image: null
    });
    const [error, setError] = useState(null);
    const [previewImage, setPreviewImage] = useState('');
    const [imageChanged, setImageChanged] = useState(false); // Track if the image has changed

    useEffect(() => {
        const token = localStorage.getItem('token');
        const fetchJob = async () => {
            try {
                const response = await axios.get(`${baseurl}/employee_view_jobs/${jobId}/`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    },
                });
                setJob(response.data);
                setPreviewImage(`${baseurl}${response.data.image}`);
            } catch (error) {
                console.error('Error fetching job:', error);
                setError('Failed to fetch job details. Please try again.');
            }
        };
        fetchJob();
    }, [jobId, baseurl]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setJob({
            ...job,
            [name]: value
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setJob({
            ...job,
            image: file
        });
        setPreviewImage(URL.createObjectURL(file));
        setImageChanged(true); // Set to true when a new image is selected
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('designation', job.designation);
        formData.append('description', job.description);
        formData.append('last_date', job.last_date);
        formData.append('requirements', job.requirements);
        
        // Append the new image only if it has changed
        if (imageChanged && job.image) {
            formData.append('image', job.image);
        }

        try {
            await axios.put(`${baseurl}/employee_view_jobs/${jobId}/`, formData, {
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
            });
            navigate('/employee_view_jobs');
        } catch (error) {
            console.error('Error updating job:', error);
            setError('Failed to update job. Please try again.');
        }
    };

    // Inline styles
    const containerStyle = {
        maxWidth: '800px',
        margin: 'auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const errorMessageStyle = {
        color: 'red',
        marginBottom: '20px'
    };

    const buttonStyle = {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '10px 15px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        maxWidth: '200px',
    };

    const buttonHoverStyle = {
        backgroundColor: '#45a049'
    };

    return (
        <div className="flex h-screen">
            <button
                className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
                onClick={() => setSidebarToggle(!sidebarToggle)}
            >
                <FaBars className="w-6 h-6" />
            </button>

            <EmployeeSidebar sidebarToggle={sidebarToggle} />

            <div className={`flex-1 transition-all duration-300 ease-in-out ${sidebarToggle ? 'ml-64' : 'ml-0'} md:ml-64`}>
                <Navbarthree sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

                <main className="p-4">
                    <div style={containerStyle}>
                        <h2>Edit Job</h2>
                        {error && <div style={errorMessageStyle}>{error}</div>}
                        <form className="edit-job-form" onSubmit={handleSubmit} style={{ width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <label>
                                    Designation:
                                    <input
                                        type="text"
                                        name="designation"
                                        value={job.designation}
                                        onChange={handleChange}
                                        required
                                        style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                                    />
                                </label>
                                <label>
                                    Description:
                                    <textarea
                                        name="description"
                                        value={job.description}
                                        onChange={handleChange}
                                        required
                                        style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                                    />
                                </label>
                                <label>
                                    Last Date:
                                    <input
                                        type="date"
                                        name="last_date"
                                        value={job.last_date}
                                        onChange={handleChange}
                                        required
                                        style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                                    />
                                </label>
                                <label>
                                    Requirements:
                                    <textarea
                                        name="requirements"
                                        value={job.requirements}
                                        onChange={handleChange}
                                        required
                                        style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                                    />
                                </label>
                                <label>
                                    Current Image:
                                    {previewImage && <img src={previewImage} alt="Job" style={{ width: '100px', height: 'auto' }} />}
                                </label>
                                <label>
                                    Upload New Image:
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        style={{ width: '100%', padding: '8px' }}
                                    />
                                </label>
                            </div>
                            <button
                                type="submit"
                                style={buttonStyle}
                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
                            >
                                Update Job
                            </button>
                        </form>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default EditJob;
