import { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Style.css';
import { FaBars } from 'react-icons/fa';
import EmployeeSidebar from './EmployeeSidebar';
import Navbarthree from './Navbarthree';
import config from "../../Functions/config";

const EmployeeAddJobs = ({ sidebarToggle, setSidebarToggle }) => {
    const baseurl=config.baseurl
    const [designation, setDesignation] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [lastDate, setLastDate] = useState('');
    const [requirements, setRequirements] = useState('');
    const [location, setLocation] = useState('');
    const [jobtype, setJobtype] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('designation', designation);
        formData.append('description', description);
        formData.append('image', image);
        formData.append('last_date', lastDate);
        formData.append('requirements', requirements);
        formData.append('location', location);
        formData.append('jobtype', jobtype);

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${baseurl}/add_job/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Token ${token}`, 
                },
            });

            alert('Job posted successfully');

            // Clear fields after successful submission
            setDesignation('');
            setDescription('');
            setImage('');
            setLastDate('');
            setRequirements('');
            setLocation('');
            setJobtype('');
        } catch (error) {
            console.error('There was an error posting the job!', error.response?.data || error);
            alert(`Error: ${JSON.stringify(error.response?.data)}`);
        }
    };

    return (
      <div className="flex h-screen">
        <button
            className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
            onClick={() => setSidebarToggle(!sidebarToggle)}
        >
            <FaBars className="w-6 h-6" />
        </button>

        <EmployeeSidebar sidebarToggle={sidebarToggle} />

        <div className={`flex-1 transition-all duration-300 ease-in-out ${sidebarToggle ? 'ml-64' : 'ml-0'} md:ml-64`}>
            <Navbarthree sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

            <main className="p-4">
                <div className='container'>
                    <form onSubmit={handleSubmit} className='job-form'>
                        <h2>Post a Job</h2>
                        <input
                            className='job-input'
                            type="text"
                            value={designation}
                            onChange={(e) => setDesignation(e.target.value)}
                            placeholder="Job Designation"
                            required
                        />
                        <input
                            className='job-input'
                            type="text"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            placeholder="Job Location"
                            required
                        />
                        <input
                            className='job-input'
                            type="text"
                            value={jobtype}
                            onChange={(e) => setJobtype(e.target.value)}
                            placeholder="Job Type"
                            required
                        />
                        <textarea
                            className='job-textarea'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Job Description"
                            required
                        />
                        <input
                            className='job-input'
                            type="file"
                            onChange={(e) => setImage(e.target.files[0])}
                            required
                        />
                        <input
                            className='job-input'
                            type="date"
                            value={lastDate}
                            onChange={(e) => setLastDate(e.target.value)}
                            required
                        />
                        <textarea
                            className='job-textarea'
                            value={requirements}
                            onChange={(e) => setRequirements(e.target.value)}
                            placeholder="Job Requirements"
                            required
                        />
                        <button type="submit" className='btn btn-success job-btn'>Post Job</button>
                    </form>
                </div>
            </main>
        </div>
      </div>
    );
};

export default EmployeeAddJobs;
