import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaBars } from 'react-icons/fa';
import SeekerSidebar from './SeekerSidebar';
import Navbartwo from './Navbartwo';
import { useNavigate } from 'react-router-dom';
import config from '../../Functions/config';

const EditSeekerProfile = ({ sidebarToggle, setSidebarToggle }) => {
    const baseurl=config.baseurl
    const [formData, setFormData] = useState({
        name: '',
        username: '',
        email: '',
        date_of_birth: '',
        mobile_number: '',
        education_details: '',
        address: '',
        profile_picture: null,
        profile_picture_url: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSeekerInfo = async () => {
            const token = localStorage.getItem('token');
            setLoading(true);
            try {
                const response = await axios.get(`${baseurl}/api/seeker_info/`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                const { name, username, email, date_of_birth, mobile_number, education_details, address, profile_picture } = response.data;
                setFormData({
                    name,
                    username,
                    email,
                    date_of_birth,
                    mobile_number,
                    education_details,
                    address,
                    profile_picture: null,
                    profile_picture_url: profile_picture || '',
                });
            } catch (error) {
                setError('Error fetching seeker information. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchSeekerInfo();
    }, []);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: files ? files[0] : value,
            profile_picture_url: files ? URL.createObjectURL(files[0]) : prevState.profile_picture_url,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
    
        if (!formData.name || !formData.username || !formData.email) {
            setError('Please fill in all required fields.');
            return;
        }
    
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('username', formData.username);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('date_of_birth', formData.date_of_birth);
        formDataToSend.append('mobile_number', formData.mobile_number);
        formDataToSend.append('education_details', formData.education_details);
        formDataToSend.append('address', formData.address);
        if (formData.profile_picture) {
            formDataToSend.append('profile_picture', formData.profile_picture);
        }
    
        const token = localStorage.getItem('token');
        setLoading(true);
        try {
            await axios.put(`${baseurl}/api/edit_seeker_info/`, formDataToSend, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            navigate('/seeker_profile');
        } catch (error) {
            // Check for specific error response
            if (error.response) {
                if (error.response.status === 400) {
                    const errors = error.response.data;
                    if (errors.username) {
                        setError('Username already exists.');
                    } else if (errors.email) {
                        setError('Email already exists.');
                    } else {
                        setError('Error updating profile. Please try again.');
                    }
                } else {
                    setError('Error updating profile. Please try again.');
                }
            } else {
                setError('Error updating profile. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex h-screen bg-gray-100">
            <button
                className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
                onClick={() => setSidebarToggle(!sidebarToggle)}
            >
                <FaBars className="w-6 h-6" />
            </button>

            <SeekerSidebar sidebarToggle={sidebarToggle} />

            <div className={`flex-1 transition-all duration-300 ease-in-out ${sidebarToggle ? 'ml-64' : 'ml-0'} md:ml-64`}>
                <Navbartwo sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

                <main className="p-4 bg-gray-50">
                    <div className="p-4 bg-white rounded-lg shadow-md">
                        <h1 className="text-2xl font-bold">Edit Profile</h1>
                        {loading && <p>Loading...</p>}
                        {error && <p className="text-red-500">{error}</p>}
                        <form onSubmit={handleSubmit} className="mt-4">
                            {[
                                { label: 'Name', name: 'name', type: 'text', required: true },
                                { label: 'Username', name: 'username', type: 'text', required: true },
                                { label: 'Email', name: 'email', type: 'email', required: true },
                                { label: 'Date of Birth', name: 'date_of_birth', type: 'date' },
                                { label: 'Mobile Number', name: 'mobile_number', type: 'tel' },
                            ].map(({ label, name, type, required }) => (
                                <div className="mt-4" key={name}>
                                    <label className="font-semibold">{label}:</label>
                                    <input
                                        type={type}
                                        name={name}
                                        value={formData[name]}
                                        onChange={handleChange}
                                        className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required={required}
                                    />
                                </div>
                            ))}
                            <div className="mt-4">
                                <label className="font-semibold">Education details:</label>
                                <textarea
                                    name="education_details"
                                    value={formData.education_details}
                                    onChange={handleChange}
                                    className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    placeholder="Enter your education information"
                                />
                            </div>
                            <div className="mt-4">
                                <label className="font-semibold">Address:</label>
                                <textarea
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="mt-4">
                                <label className="font-semibold">Profile Picture:</label>
                                <input
                                    type="file"
                                    name="profile_picture"
                                    onChange={handleChange}
                                    className="border border-gray-300 p-2 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <button type="submit" className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200">
                                Save
                            </button>
                        </form>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default EditSeekerProfile;
