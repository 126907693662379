import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaBars } from 'react-icons/fa';
import SeekerSidebar from './SeekerSidebar';
import Navbartwo from './Navbartwo';
import { useNavigate } from 'react-router-dom';
import './Style.css';
import config from '../../Functions/config';

const SeekerProfile = ({ sidebarToggle, setSidebarToggle }) => {
    const baseurl = config.baseurl;
    const [seeker, setSeeker] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showResetForm, setShowResetForm] = useState(false); // Make sure to include this
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const fetchSeekerInfo = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${baseurl}/api/seeker_info/`, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            setSeeker(response.data);
        } catch (error) {
            console.error('Error fetching seeker information:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSeekerInfo();
    }, []);

    const handleCompleteProfile = () => {
        navigate('/complete-profile');
    };

    const handleEditProfile = () => {
        navigate('/edit_seeker_profile');
    };

    const isProfileComplete = () => {
        return seeker && seeker.name && seeker.date_of_birth && seeker.mobile_number && seeker.education_details && seeker.address;
    };

    if (loading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    const getImageUrl = (url) => {
        return url && url.startsWith('http') ? url : `${baseurl}${url || 'default-profile-picture-url'}`;
    };

    const handleResetPassword = async () => {
        const hasAlphabet = /[a-zA-Z]/.test(newPassword);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
        const hasNumber = /[0-9]/.test(newPassword);
        
        if (newPassword !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
        if (newPassword.length < 6) {
            setError("Password must be at least 6 characters long.");
            return;
        }
        if (!hasAlphabet) {
            setError("Password must contain at least one letter.");
            return;
        }
        if (!hasSpecialChar) {
            setError("Password must contain at least one special character.");
            return;
        }
        if (!hasNumber) {
            setError("Password must contain at least one number.");
            return;
        }

        try {
            await axios.post(`${baseurl}/reset_password/`, { password: newPassword }, {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`
                }
            });
            alert("Password reset successfully!");
            navigate('./login');
            setNewPassword('');
            setConfirmPassword('');
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred while resetting the password.');
        }
    };

    return (
        <div className="flex h-screen bg-gray-100">
            <button
                className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
                onClick={() => setSidebarToggle(!sidebarToggle)}
            >
                <FaBars className="w-6 h-6" />
            </button>

            <SeekerSidebar sidebarToggle={sidebarToggle} />

            <div className={`flex-1 transition-all duration-300 ease-in-out ${sidebarToggle ? 'ml-64' : 'ml-0'} md:ml-64`}>
                <Navbartwo sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

                <main className="flex justify-center items-center h-full p-4 bg-gray-100">
                    <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md mx-auto">
                        <h1 className="text-3xl font-bold text-gray-800 mb-6">Seeker Profile</h1>
                        {seeker && (
                            <>
                                <div className="flex items-center mb-4">
                                    <img src={getImageUrl(seeker.profile_picture)} alt="Profile" className="w-32 h-32 rounded-full border-2 border-gray-300" />
                                    <div className="ml-4">
                                        <p className="text-lg font-semibold">{seeker.name}</p>
                                        <p className="text-gray-500">{seeker.username}</p>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <p className="text-gray-500">{seeker.email}</p>
                                    <p><strong>Date of Birth:</strong> {seeker.date_of_birth}</p>
                                    <p><strong>Mobile Number:</strong> {seeker.mobile_number}</p>
                                    <p><strong>Education Info:</strong> {seeker.education_details}</p>
                                    <p><strong>Address:</strong> {seeker.address || 'Not provided'}</p>
                                </div>
                                <button 
                                    onClick={isProfileComplete() ? handleEditProfile : handleCompleteProfile} 
                                    className="mt-4 bg-blue-500 text-white py-2 px-6 rounded transition duration-200 hover:bg-blue-600"
                                >
                                    {isProfileComplete() ? 'Edit Profile' : 'Complete Profile'}
                                </button>
                                <button 
                                    onClick={() => setShowResetForm(true)} // Show reset form
                                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mt-2"
                                >
                                    Reset Password
                                </button>
                            </>
                        )}
                    </div>
                </main>
            </div>

            {/* Modal for Reset Password */}
            {showResetForm && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-md p-6 w-96">
                        <h2 className="text-xl font-bold mb-4">Reset Password</h2>
                        {error && <div className="text-red-500">{error}</div>}
                        <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => { setNewPassword(e.target.value); setError(null); }}
                            className="border p-2 rounded w-full mb-4"
                        />
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => { setConfirmPassword(e.target.value); setError(null); }}
                            className="border p-2 rounded w-full mb-4"
                        />
                        <button 
                            onClick={handleResetPassword}
                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                        >
                            Submit
                        </button>
                        <button 
                            onClick={() => setShowResetForm(false)} // Close the form
                            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 ml-2"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SeekerProfile;
