import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import AdminSidebar from './AdminSidebar';
import Navbarone from './Navbarone';
import { FaBars } from 'react-icons/fa';
import axios from 'axios';
import config from '../../Functions/config';

const ApproveSeeker = ({ sidebarToggle, setSidebarToggle }) => {
  const baseurl = config.baseurl;
  const [seekers, setSeekers] = useState([]);
  const [selectedSeeker, setSelectedSeeker] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSeekers = async () => {
      try {
        const response = await axios.get(`${baseurl}/newseekers/`);
        const unapprovedSeekers = response.data.filter(seeker => !seeker.approved_status);
        setSeekers(unapprovedSeekers);
      } catch (error) {
        console.error('Error fetching seekers:', error);
      }
    };

    fetchSeekers();

    return () => {
      localStorage.setItem('currentPath', window.location.pathname);
    };
  }, [navigate]);

  const handleApprove = async (seekerId) => {
    try {
      const response = await axios.post(`${baseurl}/approve-seeker/${seekerId}/`);
      if (response.status === 200) {
        setSeekers(seekers.filter(seeker => seeker.user.id !== seekerId)); 
        alert('Seeker approved and password sent via email.');
      }
    } catch (error) {
      console.error('Error approving seeker:', error);
      alert('Failed to approve seeker. Please try again later.');
    }
  };

  const handleDisapprove = async (seekerId) => {
    try {
      const response = await axios.post(`${baseurl}/disapprove-seeker/${seekerId}/`);
      if (response.status === 204) {
        setSeekers(seekers.filter(seeker => seeker.user.id !== seekerId));
        alert('Seeker disapproved and deleted.');
      }
    } catch (error) {
      console.error('Error disapproving seeker:', error);
      alert('Failed to disapprove seeker. Please try again later.');
    }
  };

  const handleViewDetails = (seeker) => {
    setSelectedSeeker(seeker);
  };

  const handleCloseModal = () => {
    setSelectedSeeker(null);
  };

  return (
    <div className="flex h-screen">
      <button
        className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
        onClick={() => setSidebarToggle(!sidebarToggle)}
      >
        <FaBars className="w-6 h-6" />
      </button>

      <AdminSidebar sidebarToggle={sidebarToggle} />

      <div className={`flex-1 transition-all duration-300 ease-in-out ml-0 md:ml-64`}>
        <Navbarone sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

        <main className="p-4">
          <h1 className="text-2xl font-bold">New Seekers</h1>
          {seekers.length === 0 ? (
            <p className="text-gray-500 mt-4">No new seekers available.</p>
          ) : (
            <ul>
              {seekers.map(seeker => (
                <li key={seeker.id} className="flex justify-between items-center mt-2">
                  <span className="cursor-pointer text-blue-600" onClick={() => handleViewDetails(seeker)}>
                    {seeker.name}
                  </span>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleApprove(seeker.user.id)}
                      className={`bg-green-500 text-white p-2 rounded ${seeker.approved_status ? 'opacity-50 cursor-not-allowed' : ''}`}
                      disabled={seeker.approved_status}
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => handleDisapprove(seeker.user.id)}
                      className={`bg-red-500 text-white p-2 rounded ${!seeker.approved_status ? 'opacity-50 cursor-not-allowed' : ''}`}
                      disabled={seeker.approved_status}
                    >
                      Disapprove
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}

          {/* User Details Modal */}
          {selectedSeeker && (
            <div className="bg-white p-4 rounded-lg shadow-md mt-4">
              <h2 className="text-xl font-bold mb-2">User Details</h2>
              <p><strong>Name:</strong> {selectedSeeker.name}</p>
              <p><strong>Username:</strong> {selectedSeeker.user.username}</p>
              <p><strong>Email:</strong> {selectedSeeker.user.email}</p>
              <p><strong>Date of Birth:</strong> {selectedSeeker.date_of_birth}</p>
              <p><strong>Mobile Number:</strong> {selectedSeeker.mobile_number}</p>
              <div className="flex justify-end mt-4">
                <button className="bg-red-500 text-white p-2 rounded" onClick={handleCloseModal}>
                  Close
                </button>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default ApproveSeeker;
