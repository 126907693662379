import { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import EmployeeSidebar from './EmployeeSidebar';
import Navbarthree from './Navbarthree';
import config from "../../Functions/config";

const EmployeeViewJobs = ({ sidebarToggle, setSidebarToggle }) => {
    const baseurl = config.baseurl;
    const [jobs, setJobs] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const fetchJobs = async () => {
            const response = await axios.get(`${baseurl}/employee_view_jobs/`, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            setJobs(response.data);
        };
        fetchJobs();
    }, []);

    const getStatusColor = (status) => {
        switch (status) {
            case 'APPROVED':
                return 'text-green-600'; // Green
            case 'PENDING':
                return 'text-blue-600'; // Blue
            case 'REJECTED':
                return 'text-red-600'; // Red
            default:
                return 'text-gray-500'; // Gray for unknown status
        }
    };

    const handleEdit = (jobId) => {
        navigate(`/edit-job/${jobId}`);
    };

    const handleDelete = async (jobId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this job?");
        if (confirmDelete) {
            const token = localStorage.getItem('token');
            try {
                await axios.delete(`${baseurl}/delete_job/${jobId}/`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    },
                });
                setJobs(jobs.filter(job => job.id !== jobId));
            } catch (error) {
                console.error("Error deleting job:", error);
            }
        }
    };

    const handleViewApplicants = (jobId) => {
        navigate(`/job/${jobId}/applicants`);
    };

    return (
        <div className="flex flex-col md:flex-row h-screen">
            <button
                className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
                onClick={() => setSidebarToggle(!sidebarToggle)}
            >
                <FaBars className="w-6 h-6" />
            </button>

            <EmployeeSidebar sidebarToggle={sidebarToggle} />

            <div className={`flex-1 transition-all duration-300 ease-in-out ${sidebarToggle ? 'ml-64' : 'ml-0'} md:ml-64`}>
                <Navbarthree sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

                <main className="p-4">
                    <div className="bg-white rounded-lg shadow-md p-6">
                        <h2 className="text-2xl font-bold mb-4">View Jobs</h2>
                        {jobs.length === 0 ? (
                            <div>No jobs available</div>
                        ) : (
                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
                                {jobs.map(job => (
                                    <div key={job.id} className="border border-gray-200 rounded-lg bg-white shadow hover:shadow-lg transition-shadow duration-200 flex flex-col">
                                        {/* Job Details */}
                                        <div className="p-4 flex-grow">
                                            <h2 className="text-xl font-semibold">{job.designation}</h2>
                                            <h3 className="text-lg text-gray-600">{job.employee.company || 'No Company'}</h3>
                                            <p className="text-gray-500 text-sm">{job.employee.website}</p>
                                            <p className="text-gray-700">{job.description}</p>
                                            {job.image && (
                                                <img
                                                    src={`${baseurl}${job.image}`}
                                                    alt={job.designation}
                                                    className="w-40 h-30 rounded-md my-2"
                                                />
                                            )}
                                            <p className="text-gray-500">Posting Date: {new Date(job.posting_date).toLocaleDateString()}</p>
                                            <p className="text-gray-500">Last Date: {new Date(job.last_date).toLocaleDateString()}</p>
                                            <p className="text-gray-500">Requirements: {job.requirements}</p>
                                            <p className={`text-gray-500`}>Status: <span className={getStatusColor(job.status)}>{job.status}</span></p>
                                            <p className="text-gray-500">Applications: {job.application_count || 0}</p>
                                        </div>

                                        {/* Action Buttons */}
                                        <div className="flex flex-col mt-4 space-y-2 p-4">
                                            <button className="bg-yellow-400 text-white py-2 rounded hover:bg-yellow-500" onClick={() => handleEdit(job.id)}>Edit</button>
                                            <button className="bg-red-600 text-white py-2 rounded hover:bg-red-700" onClick={() => handleDelete(job.id)}>Delete</button>
                                            <button className="bg-gray-600 text-white py-2 rounded hover:bg-gray-700" onClick={() => handleViewApplicants(job.id)}>View Applicants</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default EmployeeViewJobs;
