import { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import EmployeeSidebar from './EmployeeSidebar';
import Navbarthree from './Navbarthree';
import config from "../../Functions/config";

const EmployeeProfile = ({ sidebarToggle, setSidebarToggle }) => {
    const baseurl=config.baseurl
    const [employee, setEmployee] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showResetForm, setShowResetForm] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [editData, setEditData] = useState({
        email: '',
        mobile_number: '',
        address: '',
        website: '',
        username: '',
        company: '',
        logo: null,
    });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${baseurl}/employee_view_profile/`, {
                    headers: { 'Authorization': `Token ${token}` },
                });
                setEmployee(response.data);
                setEditData({
                    email: response.data.email,
                    mobile_number: response.data.mobile_number,
                    address: response.data.address,
                    website: response.data.website,
                    username: response.data.username,
                    company: response.data.company,
                    logo: null,
                });
            } catch (error) {
                setError("Error loading profile.");
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, []);

    if (loading) return <div>Loading...</div>;
    
    if (!employee) return <div>No profile data</div>;

    const isVerified = employee.user_status === 2;

    const validatePassword = (password, confirmPassword) => {
        if (password !== confirmPassword) return "Passwords do not match.";
        if (password.length < 6) return "Password must be at least 6 characters long.";
        if (!/[a-zA-Z]/.test(password)) return "Password must contain at least one letter.";
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) return "Password must contain at least one special character.";
        if (!/[0-9]/.test(password)) return "Password must contain at least one number.";
        return null;
    };

    const handleResetPassword = async () => {
        setError(null);
        const validationError = validatePassword(newPassword, confirmPassword);
        if (validationError) {
            setError(validationError);
            return;
        }

        try {
            await axios.post(`${baseurl}/reset_password/`, { password: newPassword }, {
                headers: { 'Authorization': `Token ${localStorage.getItem('token')}` }
            });
            alert("Password reset successfully!");
            navigate('./login');
            setNewPassword('');
            setConfirmPassword('');
            setShowResetForm(false);
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred while resetting the password.');
        }
    };

    return (
       
            <div className="flex h-screen">
              <button
                className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
                onClick={() => setSidebarToggle(!sidebarToggle)}
              >
                <FaBars className="w-6 h-6" />
              </button>
        
              <EmployeeSidebar sidebarToggle={sidebarToggle} />
        
              <div className={`flex-1 transition-all duration-300 ease-in-out ${sidebarToggle ? 'ml-64' : 'ml-0'} md:ml-64`}>
                <Navbarthree sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
        
                <main className="p-4">
    
        <div className="header-container text-center mb-4">
            <h2 className="text-xl font-bold">Profile</h2>
        </div>
        <div className="profile-header flex flex-col md:flex-row items-center md:space-x-4 mt-4">
            {employee.logo && (
                <img
                    src={`${baseurl}${employee.logo}`}
                    alt="Logo"
                    className="profile-logo w-32 h-32 object-cover rounded-full mb-4 md:mb-0"
                />
            )}
            <div className="text-center md:text-left">
                <h4 className="text-xl">{employee.username}{isVerified && <span className="verification-mark"> ✔️</span>}</h4>
                <p>Email: {employee.email}</p>
            </div>
        </div>
        <div className="profile-details mt-4">
            <h2 className="text-xl font-semibold">{employee.company}</h2>
            <p>Mobile: {employee.mobile_number}</p>
            <p className="text-sm">Website: <a href={employee.website} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">{employee.website}</a></p>
            <p>Address: {employee.address}</p>
            <p className='text-red-500'>Status: {employee.user_status === 1 ? 'Not Verified' : 'Verified'}</p>
        </div>

        <div className="button-group mt-4 flex flex-col md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-2">
            <button 
                onClick={() => setShowResetForm(true)}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 w-full md:w-auto"
            >
                Reset Password
            </button>
        </div>

        {showResetForm && (
            <div className="bg-white shadow-md rounded-lg p-6 mt-4">
                <h2 className="text-xl font-bold mb-4">Reset Password</h2>
                {error && <div className="text-red-500 mb-4">{error}</div>} {/* Display error here */}
                <input
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => {setNewPassword(e.target.value); setError(null);}}
                    className="border p-2 rounded w-full mb-4"
                />
                <input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => {setConfirmPassword(e.target.value); setError(null);}}
                    className="border p-2 rounded w-full mb-4"
                />
                <div className="flex space-x-2">
                    <button 
                        onClick={handleResetPassword}
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 w-full md:w-auto"
                    >
                        Submit
                    </button>
                    <button 
                        onClick={() => setShowResetForm(false)}
                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 w-full md:w-auto"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        )}
    
</main>
            </div>
        </div>
    );
};

export default EmployeeProfile;
