import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaBars } from 'react-icons/fa';
import SeekerSidebar from './SeekerSidebar';
import Navbartwo from './Navbartwo';
import config from '../../Functions/config';

const SeekerNotification = ({ sidebarToggle, setSidebarToggle }) => {
  const baseurl = config.baseurl;
  const [preferences, setPreferences] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [newPreference, setNewPreference] = useState({ company: '', designation: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchNotificationsAndPreferences = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');

      // Fetch job notifications
      const notificationsResponse = await axios.get(`${baseurl}/api/job_notifications/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setNotifications(notificationsResponse.data);

      // Fetch user preferences
      const preferencesResponse = await axios.get(`${baseurl}/api/notification_preferences/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setPreferences(preferencesResponse.data);
    } catch (err) {
      setError('Failed to load data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotificationsAndPreferences();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPreference(prev => ({ ...prev, [name]: value }));
  };

  const handleAddPreference = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${baseurl}/api/notification_preferences/`, newPreference, {
        headers: { Authorization: `Token ${token}` },
      });
      setPreferences(prev => [...prev, response.data]);
      setNewPreference({ company: '', designation: '' });
    } catch (err) {
      alert('Failed to add preference.');
    }
  };

  const handleDeletePreference = async (id) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${baseurl}/api/notification_preferences/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setPreferences(prev => prev.filter(pref => pref.id !== id));
    } catch (err) {
      alert('Failed to delete preference.');
    }
  };

  // Styles
  const styles = {
    mainContainer: {
      backgroundColor: '#f8f9fa',
      minHeight: 'calc(100vh - 4rem)', // Adjust for navbar height
      padding: '2rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },
    title: {
      color: '#343a40',
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    sectionTitle: {
      color: '#495057',
      fontSize: '1.5rem',
      fontWeight: '600',
      marginBottom: '1rem',
    },
    list: {
      listStyleType: 'none',
      padding: 0,
    },
    listItem: {
      background: 'white',
      border: '1px solid #dee2e6',
      borderRadius: '0.25rem',
      padding: '1rem',
      transition: 'box-shadow 0.3s, transform 0.3s',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    },
    input: {
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
      padding: '0.5rem',
      width: '100%',
      margin: '0.5rem 0',
    },
    button: {
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '0.25rem',
      padding: '0.5rem 1rem',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
    },
    redText: {
      color: '#dc3545',
    },
  };

  return (
    <div className="flex h-screen">
      <button
        className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
        onClick={() => setSidebarToggle(!sidebarToggle)}
      >
        <FaBars className="w-6 h-6" />
      </button>

      <SeekerSidebar sidebarToggle={sidebarToggle} />

      <div className={`flex-1 transition-all duration-300 ease-in-out ${sidebarToggle ? 'ml-64' : 'ml-0'} md:ml-64`}>
        <Navbartwo sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
        <main style={styles.mainContainer}>
          <h2 style={styles.title}>Job Notifications and Preferences</h2>

          {/* Job Notifications List */}
          <h2 style={styles.sectionTitle}>Job Notifications</h2>
          {loading ? (
            <p>Loading notifications...</p>
          ) : error ? (
            <p style={styles.redText}>{error}</p>
          ) : notifications.length === 0 ? (
            <p>No job notifications found.</p>
          ) : (
            <ul style={styles.list}>
              {notifications.map(notification => (
                <li key={notification.id} style={styles.listItem}>
                  <p>{notification.company} posted job {notification.designation}</p>
                </li>
              ))}
            </ul>
          )}

          {/* Add Preference Section */}
          <div>
            <h2 style={styles.sectionTitle}>Add New Preference</h2>
            <div className="flex flex-col md:flex-row gap-2 mb-4">
              <input
                type="text"
                name="company"
                value={newPreference.company}
                onChange={handleInputChange}
                placeholder="Company Name"
                style={styles.input}
              />
              <input
                type="text"
                name="designation"
                value={newPreference.designation}
                onChange={handleInputChange}
                placeholder="Job Title"
                style={styles.input}
              />
              <button onClick={handleAddPreference} style={styles.button}>
                Add
              </button>
            </div>
          </div>

          {/* Preferences List */}
          <h2 style={styles.sectionTitle}>Current Preferences</h2>
          {preferences.length === 0 ? (
            <p>No preferences found.</p>
          ) : (
            <ul style={styles.list}>
              {preferences.map(pref => (
                <li key={pref.id} style={styles.listItem} className="flex justify-between items-center">
                  <div>
                    <p>{pref.designation} at {pref.company}</p>
                  </div>
                  <button 
                    onClick={() => handleDeletePreference(pref.id)} 
                    style={{ ...styles.button, backgroundColor: 'transparent', color: '#dc3545' }}
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
          )}
        </main>
      </div>
    </div>
  );
};

export default SeekerNotification;
