import React, { useEffect, useState } from 'react';
import AdminSidebar from './AdminSidebar';
import Navbarone from './Navbarone';
import { FaBars } from 'react-icons/fa';
import axios from 'axios';
import config from '../../Functions/config';

const Approvejob = ({ sidebarToggle, setSidebarToggle }) => {
  const baseurl = config.baseurl;
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${baseurl}/admin_approve_jobs`);
        setJobs(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  const handleApprove = async (jobId) => {
    try {
      await axios.post(`${baseurl}/approve_job/${jobId}/`);
      setJobs(jobs.filter((job) => job.id !== jobId));
      alert(`Job approved successfully!`);
    } catch (error) {
      console.error('Error approving job:', error.response ? error.response.data : error.message);
    }
  };

  const handleDisapprove = async (jobId) => {
    try {
      await axios.post(`${baseurl}/disapprove_job/${jobId}/`);
      setJobs(jobs.filter((job) => job.id !== jobId));
      alert(`Job Disapproved successfully!`);
    } catch (error) {
      console.error('Error disapproving job:', error.response ? error.response.data : error.message);
    }
  };

  // Filter jobs based on the search term
  const filteredJobs = searchTerm
    ? jobs.filter(job =>
        job.designation && job.designation.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : jobs; // Show all jobs if no search term

  return (
    <div className="flex h-screen">
      <button
        className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
        onClick={() => setSidebarToggle(!sidebarToggle)}
      >
        <FaBars className="w-6 h-6" />
      </button>

      <AdminSidebar sidebarToggle={sidebarToggle} />

      <div className={`flex-1 transition-all duration-300 ease-in-out ml-0 md:ml-64`}>
        <Navbarone sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

        <main className="pt-4">
          <div className='container-fluid'>
            <div className="mb-4">
              {/* <input
                type="text"
                placeholder="Search by job designation"
                className="border rounded-lg p-2 w-full"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              /> */}
            </div>

            {loading && <p className="text-center text-lg">Loading jobs...</p>}
            {error && <p className="text-red-500 text-center">Error fetching jobs: {error}</p>}
            {!loading && !error && (
              <ul className="flex flex-wrap space-y-4">
                <h2 className="font-bold text-xl w-full">Jobs Awaiting Approval</h2>
                {filteredJobs.length > 0 ? (
                  filteredJobs.map(job => (
                    <li key={job.id} className="flex flex-col justify-between items-start border rounded-lg p-4 bg-white shadow hover:shadow-lg transition-shadow w-full md:w-1/2">
                      <div className="flex-1">
                        <h3 className="text-xl font-bold">{job.designation}</h3>
                        <p className="text-gray-600 italic">{job.employee.company || 'No Company'}</p>
                        <p className="text-gray-600">Posting Date: {new Date(job.posting_date).toLocaleDateString()}</p>
                        <p className="text-gray-600">Last Date: {new Date(job.last_date).toLocaleDateString()}</p>
                        <p className="text-gray-700">Description: <br /> {job.description}</p>
                        <p className="text-gray-700">Requirements: <br /> {job.requirements}</p>
                        
                        {job.image && (
                          <img
                            src={`${baseurl}${job.image}`}
                            alt={job.designation}
                            className="w-80 h-30 rounded-md my-2 object-cover"
                          />
                        )}
                      </div>
                      <div>
                        <button
                          className="bg-green-600 text-white py-2 px-4 rounded mr-2"
                          onClick={() => handleApprove(job.id)}
                        >
                          Approve
                        </button>
                        <button
                          className="bg-red-600 text-white py-2 px-4 rounded"
                          onClick={() => handleDisapprove(job.id)}
                        >
                          Disapprove
                        </button>
                      </div>
                    </li>
                  ))
                ) : (
                  <p className="text-center w-full">No jobs found </p>
                )}
              </ul>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Approvejob;
