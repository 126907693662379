import React, { useEffect, useState } from 'react';
import { FaBars, FaBell, FaUserCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Style.css';
import config from '../../Functions/config';

const Navbarthree = ({ sidebarToggle, setSidebarToggle }) => {
  const baseurl = config.baseurl;
  const [pendingApplicationsCount, setPendingApplicationsCount] = useState(0);

  useEffect(() => {
    // Fetch pending applications count from your API
    const fetchPendingApplicationsCount = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${baseurl}/api/pending-applications-count`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        if (!response.ok) {
          console.error('Failed to fetch pending applications count:', response.statusText);
          return;
        }

        const data = await response.json();
        setPendingApplicationsCount(data.count || 0); // Fallback to 0 if count is undefined
      } catch (error) {
        console.error('Error fetching pending applications count:', error);
      }
    };

    fetchPendingApplicationsCount();
  }, [baseurl]);

  return (
    <nav className='bg-custom-blue px-4 py-3 flex justify-between items-center w-100'>
      <div className='flex items-center text-xl'>
        <span className='text-white font-semibold'>JobPortal</span>
      </div>
      <div className='flex items-center gap-x-5'>
        <div className='relative md:w-65'></div>
        <div className='text-white relative'>
          <Link to="/employeenotification"> {/* Change to your actual route */}
            <FaBell className='w-6 h-6' />
            {pendingApplicationsCount > 0 && (
              <span className='absolute top-0 right-0 bg-red-600 text-white rounded-full text-xs px-1'>
                {pendingApplicationsCount}
              </span>
            )}
          </Link>
        </div>
        <div className='relative'>
          <button className='text-white'>
            <div className='z-10 hidden bg-white absolute rounded-lg shadow w-32 group-focus:block top-full right-0'>
              <ul className='py-2 text-sm text-gray-950'>
                <li>
                  <Link to='/' className='link'>
                    Profile
                  </Link>
                </li>
              </ul>
            </div>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbarthree;
