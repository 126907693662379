import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import AdminSidebar from './AdminSidebar';
import Navbarone from './Navbarone';
import { FaBars } from 'react-icons/fa';
import axios from 'axios';
import config from '../../Functions/config';

const Approveemployee = ({ sidebarToggle, setSidebarToggle }) => {
    const baseurl = config.baseurl;
    const [employee, setEmployee] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${baseurl}/newemployee/`);
                const unapprovedEmployees = response.data.filter(employee => !employee.approved_status);
                setEmployee(unapprovedEmployees);
            } catch (error) {
                console.error('Error fetching employee:', error);
            }
        };
        fetchEmployees();
        return () => {
            localStorage.setItem('currentPath', window.location.pathname);
        };
    }, [navigate]);

    const handleApprove = async (employeeID) => {
        try {
            const response = await axios.post(`${baseurl}/approve-employee/${employeeID}/`);
            if (response.status === 200) {
                setEmployee(employee.filter(employees => employees.id !== employeeID)); 
                alert('Employee approved and password sent via email.');
            }
        } catch (error) {
            console.error('Error approving seeker:', error);
            alert('Failed to approve seeker. Please try again later.');
        }
    };

    const handleDisapprove = async (employeeID) => {
        try {
            const response = await axios.post(`${baseurl}/disapprove_employee/${employeeID}/`);
            if (response.status === 204) {
                setEmployee(employee.filter(employees => employees.id !== employeeID)); 
                alert('Employee disapproved and deleted.');
            }
        } catch (error) {
            console.error('Error disapproving seeker:', error);
            alert('Failed to disapprove seeker. Please try again later.');
        }
    };

    const handleViewDetails = (seeker) => {
        setSelectedEmployee(seeker);
    };

    const handleCloseModal = () => {
        setSelectedEmployee(null);
    };

    return (
        <div className="flex h-screen">
      <button
        className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
        onClick={() => setSidebarToggle(!sidebarToggle)}
      >
        <FaBars className="w-6 h-6" />
      </button>

      <AdminSidebar sidebarToggle={sidebarToggle} />

      <div className={`flex-1 transition-all duration-300 ease-in-out ml-0 md:ml-64`}>
        <Navbarone sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

        <main className="p-4">
                    <h1 className="text-2xl font-bold">New Employees</h1>
                    {employee.length === 0 ? (
                        <p className="text-gray-500 mt-4">No new employees found.</p>
                    ) : (
                        <ul>
                            {employee.map(emp => (
                                <li key={emp.id} className="flex justify-between items-center mt-2">
                                    <span className="cursor-pointer text-blue-600" onClick={() => handleViewDetails(emp)}>
                                        {emp.company}
                                    </span>
                                    <div className="flex space-x-2">
                                        <button
                                            onClick={() => handleApprove(emp.id)}
                                            className={`bg-green-500 text-white p-2 rounded ${emp.approved_status ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            disabled={emp.approved_status}
                                           
                                        >
                                            Approve
                                        </button>
                                        <button
                                            onClick={() => handleDisapprove(emp.id)}
                                            className={`bg-red-500 text-white p-2 rounded ${!emp.approved_status ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            disabled={emp.approved_status}
                                           
                                        >
                                            Disapprove
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}

                    {/* User Details Modal */}
                    {selectedEmployee && (
                        <div className="bg-white p-4 rounded-lg shadow-md mt-4">
                            <h2 className="text-xl font-bold mb-2">User Details</h2>
                            {selectedEmployee.logo && (
                                <img src={selectedEmployee.logo} alt={`${selectedEmployee.company} Logo`} className="h-10 mb-1" />
                            )}
                            <p><strong>Name:</strong> {selectedEmployee.company}</p>
                            <p><strong>Username:</strong> {selectedEmployee.username}</p>
                            <p><strong>Website:</strong> {selectedEmployee.website}</p>
                            <p><strong>Email:</strong> {selectedEmployee.email}</p>
                            <p><strong>Mobile Number:</strong> {selectedEmployee.mobile_number}</p>
                            <div className="flex justify-end mt-4">
                                <button className="bg-red-500 text-white p-2 rounded" onClick={handleCloseModal}>
                                    Close
                                </button>
                            </div>
                        </div>
                    )}
                </main>
            </div>
        </div>
    );
};

export default Approveemployee;
