import React, { useState } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import config from '../../Functions/config';

const Seekerreg = () => {
    const baseurl = config.baseurl;

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        name: '',
        mobile_number: '',
        date_of_birth: ''
    });
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validateMobileNumber = (mobile) => {
        const re = /^[0-9]{10}$/; // Adjust regex to fit your mobile number format
        return re.test(mobile);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null); // Clear previous errors

        // Validate email and mobile number
        if (!validateEmail(formData.email)) {
            setError('Invalid email format.');
            return;
        }

        if (!validateMobileNumber(formData.mobile_number)) {
            setError('Mobile number must be 10 digits long.');
            return;
        }

        try {
            const response = await axios.post(`${baseurl}/register/`, formData);
            alert('Registration successful,Wait for Admin approval');
            setFormData({
                username: '',
                email: '',
                name: '',
                mobile_number: '',
                date_of_birth: ''
            });
            setError(null);
        } catch (error) {
            if (error.response && error.response.data) {
                // Check for specific error messages
                if (error.response.data.error) {
                    setError(error.response.data.error);
                } else if (error.response.data.username) {
                    setError('Username already exists.');
                } else if (error.response.data.email) {
                    setError('Email already exists.');
                } else {
                    setError('Registration failed: ' + JSON.stringify(error.response.data));
                }
            } else {
                setError('Registration failed: ' + error.message);
            }
        }
    };

    return (
        <>
            <Navbar/>
            <div className="registration-form">
                <h2>Seeker Registration</h2>
                {error && <p className="error">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="date_of_birth">Date of Birth:</label>
                    <input
                        type="date"
                        id="date_of_birth"
                        name="date_of_birth"
                        value={formData.date_of_birth}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="mobile_number">Mobile Number:</label>
                    <input
                        type="text"
                        id="mobile_number"
                        name="mobile_number"
                        value={formData.mobile_number}
                        onChange={handleChange}
                        required
                    />
                    
                    {/* Button Container */}
                    <div className="button-container">
                        <button type="submit">Register</button>
                        <button type="button" onClick={() => setFormData({
                            username: '',
                            email: '',
                            name: '',
                            mobile_number: '',
                            date_of_birth: ''
                        })}>
                            Clear
                        </button>
                        <button type="button" onClick={() => window.location.href = '/'}>
                            Back to Home
                        </button>
                    </div>
                </form>
            </div>

            <style jsx>{`
                .registration-form {
                    max-width: 800px;
                    margin: 50px auto;
                    padding: 50px;
                    border-radius: 8px;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                    background-color: #fff;
                }

                .registration-form h2 {
                    text-align: center;
                    margin-bottom: 20px;
                    color: rgb(4, 4, 80);
                }

                .registration-form label {
                    display: block;
                    margin: 10px 0 5px;
                    font-weight: 600;
                }

                .registration-form input {
                    width: 100%;
                    padding: 10px;
                    margin-bottom: 15px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    font-size: 1rem;
                }

                .registration-form input:focus {
                    border-color: rgb(4, 4, 80);
                    outline: none;
                }

                .registration-form .error {
                    color: red;
                    text-align: center;
                    margin-bottom: 10px;
                }

                .button-container {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;
                }

                .button-container button {
                    flex: 1;
                    padding: 10px;
                    margin: 0 5px;
                    border: none;
                    border-radius: 4px;
                    font-size: 1rem;
                    color: white;
                    background-color: rgb(4, 4, 80);
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                }

                .button-container button:hover {
                    background-color: white;
                }
            `}</style>
        </>
    );
};

export default Seekerreg;
