import { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import EmployeeSidebar from './EmployeeSidebar';
import Navbarthree from './Navbarthree';
import config from "../../Functions/config";

const EmployeeNotification = ({ sidebarToggle, setSidebarToggle }) => {
    const baseurl = config.baseurl;
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedApplicant, setSelectedApplicant] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [expiredJobs, setExpiredJobs] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get(`${baseurl}/api/notifications/`, {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('token')}`,
                    },
                });
                setNotifications(response.data);
            } catch (error) {
                console.error("Error fetching notifications:", error);
                setError("Failed to load notifications.");
            } finally {
                setLoading(false);
            }
        };

        const fetchExpiredJobs = async () => {
            try {
                const response = await axios.get(`${baseurl}/api/expired_jobs/`, {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('token')}`,
                    },
                });
                setExpiredJobs(response.data.expired_jobs);
            } catch (error) {
                console.error("Error fetching expired jobs:", error);
            }
        };

        fetchNotifications();
        fetchExpiredJobs();
    }, []);

    const openModal = async (notification) => {
        const notificationId = notification.id;
        await updateApplicationStatus(notificationId);
        setSelectedApplicant(notification);
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedApplicant(null);
        setModalOpen(false);
    };

    const updateApplicationStatus = async (notificationId) => {
        try {
            await axios.put(`${baseurl}/update_application_status/${notificationId}/`, {
                status: 'VIEWED'
            }, {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                },
            });
        } catch (error) {
            console.error("Error updating application status:", error);
        }
    };

    const styles = {
        container: {
            display: 'flex',
            height: '100vh',
            backgroundColor: '#f7fafc',
        },
        sidebarButton: {
            position: 'fixed',
            top: '1rem',
            left: '1rem',
            zIndex: 50,
            backgroundColor: '#4a90e2',
            color: '#fff',
            padding: '0.5rem',
            borderRadius: '50%',
        },
        main: {
            flex: 1,
            transition: 'margin-left 0.3s',
            marginLeft: sidebarToggle ? '16rem' : '0',
        },
        header: {
            padding: '1.5rem',
            fontSize: '2rem',
            fontWeight: 'bold',
        },
        notificationList: {
            backgroundColor: '#fff',
            borderRadius: '0.5rem',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            maxWidth: '600px',
            margin: '0 auto',
        },
        notificationItem: {
            padding: '1rem',
            borderBottom: '1px solid #e5e5e5',
            cursor: 'pointer',
        },
        notificationItemHover: {
            backgroundColor: '#f1f1f1',
        },
        modalOverlay: {
            position: 'fixed',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 50,
        },
        modalContent: {
            backgroundColor: '#fff',
            padding: '1.5rem',
            borderRadius: '0.5rem',
            maxWidth: '400px',
            width: '100%',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        },
        expiredJobs: {
            marginTop: '1rem',
            padding: '1rem',
            backgroundColor: '#feecb5',
            border: '1px solid #f0ad4e',
            borderRadius: '0.5rem',
            color: '#856404',
        },
        closeButton: {
            marginTop: '1rem',
            color: '#dc3545',
            cursor: 'pointer',
        },
    };

    return (
        <div className="flex flex-col md:flex-row h-screen">
            <button
                className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
                onClick={() => setSidebarToggle(!sidebarToggle)}
            >
                <FaBars className="w-6 h-6" />
            </button>

            <EmployeeSidebar sidebarToggle={sidebarToggle} />

            <div className={`flex-1 transition-all duration-300 ease-in-out ${sidebarToggle ? 'ml-64' : 'ml-0'} md:ml-64`}>
                <Navbarthree sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

                <main className="p-4">
                    <h2 style={styles.header}>Notifications</h2>
                    {loading ? (
                        <p style={{ color: '#4a4a4a' }}>Loading notifications...</p>
                    ) : error ? (
                        <p style={{ color: '#e74c3c' }}>{error}</p>
                    ) : notifications.length === 0 ? (
                        <p style={{ color: '#4a4a4a' }}>No notifications available.</p>
                    ) : (
                        <ul style={styles.notificationList}>
                            {notifications.map(notification => (
                                <li
                                    key={notification.id}
                                    style={styles.notificationItem}
                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.notificationItemHover.backgroundColor}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
                                >
                                    <p style={{ color: '#4a4a4a' }}>{notification.message}</p>
                                    <p style={{ color: '#7d7d7d', fontSize: '0.875rem' }}>{new Date(notification.created_at).toLocaleString()}</p>
                                    {notification.application && notification.application.seeker && (
                                        <div style={{ marginTop: '0.5rem' }}>
                                            <p style={{ fontWeight: 'bold' }}>Applicant:</p>
                                            <p>Name: {notification.application.seeker.name}</p>
                                            <p>Email: {notification.application.seeker.user.email}</p>
                                            <button 
                                                style={{ color: '#007bff', marginTop: '0.5rem', cursor: 'pointer' }}
                                                onClick={() => openModal(notification)}
                                            >
                                                View Applicant
                                            </button>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}

                    {expiredJobs.length > 0 && (
                        <div style={styles.expiredJobs}>
                            <strong>Expired Jobs:</strong> 
                            <ul>
                                {expiredJobs.map((job, index) => (
                                    <li key={index}>{job}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </main>
            </div>

            {modalOpen && (
                <div style={styles.modalOverlay}>
                    <div style={styles.modalContent}>
                        <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>Applicant Details</h2>
                        {selectedApplicant && (
                            <>
                                <p><strong>Name:</strong> {selectedApplicant.application.seeker.name}</p>
                                <p><strong>Email:</strong> {selectedApplicant.application.seeker.user.email}</p>
                                <p><strong>Mobile Number:</strong> {selectedApplicant.application.seeker.mobile_number}</p>
                                <p><strong>Education:</strong> {selectedApplicant.application.seeker.education_details}</p>
                                <p><strong>Address:</strong> {selectedApplicant.application.seeker.address}</p>
                            </>
                        )}
                        <button 
                            style={styles.closeButton}
                            onClick={closeModal}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EmployeeNotification;
