import React, { useEffect, useState } from 'react';
import AdminSidebar from './AdminSidebar';
import Navbarone from './Navbarone';
import { FaBars } from 'react-icons/fa';
import axios from 'axios';
import config from '../../Functions/config';

const AdminNotifications = ({ sidebarToggle, setSidebarToggle }) => {
  const baseurl = config.baseurl;
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${baseurl}/api/admin_notifications/`);
        setNotifications(response.data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setError('Failed to load notifications. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const handleDeleteNotification = async (id) => {
    try {
      await axios.delete(`${baseurl}/api/admin_notifications/${id}/`);
      setNotifications(notifications.filter(notification => notification.id !== id));
      alert('Notification deleted successfully.');
    } catch (error) {
      console.error('Error deleting notification:', error);
      alert('Failed to delete notification. Please try again later.');
    }
  };

  return (
    <div className="flex h-screen">
      <button
        className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
        onClick={() => setSidebarToggle(!sidebarToggle)}
      >
        <FaBars className="w-6 h-6" />
      </button>

      <AdminSidebar sidebarToggle={sidebarToggle} />

      <div className={`flex-1 transition-all duration-300 ease-in-out ml-0 md:ml-64`}>
        <Navbarone sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

        <main className="p-4">
          <h2 className="text-2xl font-bold mb-4">Admin Notifications</h2>
          {loading ? (
            <p>Loading notifications...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : notifications.length === 0 ? (
            <p>No notifications</p>
          ) : (
            notifications.map((notification) => (
              <div key={notification.id} className="bg-white p-4 rounded shadow mb-2 flex justify-between items-center">
                <div>
                  <p className='text-green-800'>{notification.message}</p>
                  <small>{new Date(notification.created_at).toLocaleString()}</small>
                </div>
                <button
                  onClick={() => handleDeleteNotification(notification.id)}
                  className="bg-red-500 text-white p-1 rounded ml-4"
                >
                  Delete
                </button>
              </div>
            ))
          )}
        </main>
      </div>
    </div>
  );
};

export default AdminNotifications;
