import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Home from './components/screens/Home';
import Login from './components/screens/Login';
import Seekerreg from './components/screens/Seekerreg';
import Employeereg from './components/screens/Employeereg';
import Seeker_home from './components/screens/Seeker_home';
import Employee_home from './components/screens/Employee_home';
import Admin from './components/screens/Admin';
import AdminUpdateProfile from './components/screens/AdminUpdateProfile';
import { UserContext, UserProvider } from './contexts/UserContext';
import AdminNavbar from './components/screens/AdminNavbar';
import EmployeeNavbar from './components/screens/EmployeeNavbar';
import AdminViewSeeker from './components/screens/AdminViewSeeker';
import AdminViewProfile from './components/screens/AdminViewProfile';
import EmployeeProfile from './components/screens/EmployeeProfile';
import AdminViewEmployess from './components/screens/AdminViewEmployess';
import EmployeeAddJobs from './components/screens/EmployeeAddJobs';
import Approvejob from './components/screens/Approvejob';
import EmployeeViewJobs from './components/screens/EmployeeViewJobs';
import EditJob from './components/screens/EditJob';
import AdminSidebar from './components/screens/AdminSidebar';
import Navbarone from './components/screens/Navbarone';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchJob from './components/screens/SearchJob';
import SeekerProfile from './components/screens/SeekerProfile';
import CompleteProfile from './components/screens/CompleteProfile';
import AdminNotifications from './components/screens/AdminNotifications';
import EmployeeNotification from './components/screens/EmployeeNotification';
import JobApplicants from './components/screens/JobApplicants';
import SeekerViewAppliedJob from './components/screens/SeekerViewAppliedJob';
import AdminViewAllJob from './components/screens/AdminViewAllJob';
import EditSeekerProfile from './components/screens/EditSeekerProfile';
import SeekerNotification from './components/screens/SeekerNotification';
import ApproveSeeker from './components/screens/ApproveSeeker';
import Approveemployee from './components/screens/Approveemployee';


// ProtectedRoute component to check user type and redirect
const ProtectedRoute = ({ userType, element }) => {
  const { user } = useContext(UserContext);

  if (!user) {
    return <Navigate to='/login' />;
  }

  if (user.user_type !== userType) {
    return <Navigate to='/login' />;
  }

  return element;
};

function App() {
  const [sidebarToggle,setSidebarToggle]=useState(false)
  return (
   
    <UserProvider>
      
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Seekerreg />} />
          <Route path='/eregister' element={<Employeereg />} />
         

          {/* Role-based Routes */}
          <Route path='/admin-home' element={
            <ProtectedRoute userType={1} element={
            
              
             
                   
                   <Admin sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

               
            
             
            } />
          } />
           <Route path='/seeker-home' element={
            <ProtectedRoute userType={2} element={
              <>
              <ToastContainer />
              <Seeker_home sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
            
              </>
            } />
          } />
          <Route path='/employee-home' element={
            <ProtectedRoute userType={3} element={
              <>
               
                <Employee_home  sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
              </>
            } />
          } />
          <Route path='/adminviewprofile' element={
            <ProtectedRoute userType={1} element={<AdminViewProfile sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
           <Route path='/adminnotifications' element={
            <ProtectedRoute userType={1} element={<AdminNotifications sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
            <Route path='/approve_seeker'element={
            <ProtectedRoute userType={1} element={<ApproveSeeker sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
           <Route path='/approve_employee'element={
            <ProtectedRoute userType={1} element={<Approveemployee sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
          <Route path='/edit_admin_profile' element={
            <ProtectedRoute userType={1} element={<AdminUpdateProfile  sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
          <Route path='/admin_view_seeker' element={
            <ProtectedRoute userType={1} element={<AdminViewSeeker sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
            <Route path='/admin_approve_jobs' element={
            <ProtectedRoute userType={1} element={<Approvejob sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
         
             <Route path='/admin_view_employe' element={
            <ProtectedRoute userType={1} element={<AdminViewEmployess sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
            <Route path='/admin_view_job' element={
            <ProtectedRoute userType={1} element={<AdminViewAllJob sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
           <Route path='/employee_profile' element={
            <ProtectedRoute userType={3} element={<EmployeeProfile sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
            <Route path='/add_jobs' element={
            <ProtectedRoute userType={3} element={<EmployeeAddJobs sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
            <Route path='/employee_view_jobs' element={
            <ProtectedRoute userType={3} element={<EmployeeViewJobs  sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}
            />} />
          } />
          <Route path='/employeenotification' element={
            <ProtectedRoute userType={3} element={<EmployeeNotification  sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}
            />} />
          } />
          <Route path="/edit-job/:jobId" element={<EditJob sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}
          />} />
         
          <Route path='/search_job' element={
            <ProtectedRoute userType={2} element={<SearchJob sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
           <Route path='/seeker_profile' element={
            <ProtectedRoute userType={2} element={<SeekerProfile sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
            <Route path='/complete-profile' element={
            <ProtectedRoute userType={2} element={<CompleteProfile sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
           <Route path='/applied_job' element={
            <ProtectedRoute userType={2} element={<SeekerViewAppliedJob sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
           <Route path='/edit_seeker_profile' element={
            <ProtectedRoute userType={2} element={<EditSeekerProfile sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
           <Route path='/seeker_notification' element={
            <ProtectedRoute userType={2} element={<SeekerNotification sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          } />
          <Route path="/job/:jobId/applicants" element={<JobApplicants sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>} />
          {/* Redirect to login if the route does not match */}
          <Route path='*' element={<Navigate to='/login' />} />
          
        </Routes>
   
    </UserProvider>
  );
}

export default App;
