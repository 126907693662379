import React from 'react';
import Navbar from './Navbar';
import './Home.css';
import herohome from '../assets/herohome.jpg'
import job1 from '../assets/job1.webp'
import job2 from '../assets/job2.webp'
import job3 from '../assets/job3.jpg'


const Home = () => {
  return (
    <>
     <Navbar />
    <div className="home-container">
     
      <header className="hero">
        <div className="hero-content">
          <h2>Welcome to Job Portal</h2>
          <p>Your dream job is just a click away!</p>
          <button className="cta-button">Find Jobs</button>
        </div>
        <div className="hero-image">
          <img src={herohome} alt="Hero" />
        </div>
      </header>
      <center><h2>Featured Jobs</h2></center>
      <section className="job-listings">
    
        <div className="job-card">
          <img src={job1} alt="Job 1" />
          <h3>Software Engineer</h3>
          <p>ABCD Tech</p>
          <button className="apply-button">Apply Now</button>
        </div>
        <div className="job-card">
          <img src={job2} alt="Job 2" />
          <h3>Internet Marketing</h3>
          <p>Tech Solutions</p>
          <button className="apply-button">Apply Now</button>
        </div>
        <div className="job-card">
          <img src={job3} alt="Job 3" />
          <h3>Business Analyst</h3>
          <p>Soft Tech</p>
          <button className="apply-button">Apply Now</button>
        </div>
      </section>
      <section className="testimonials">
        <h2>What Our Users Say</h2>
        <div className="testimonial-card">
          <p>"This job portal helped me find my dream job in no time! Highly recommend!"</p>
          <h4>- Ann Maria</h4>
        </div>
        <div className="testimonial-card">
          <p>"Great platform! The job search process was smooth and efficient."</p>
          <h4>- John Mathew</h4>
        </div>
        <div className="testimonial-card">
          <p>"I landed multiple interviews thanks to this portal. Fantastic experience!"</p>
          <h4>- Siya</h4>
        </div>
      </section>
      <footer className="footer">
        <p>&copy; 2024 Job Portal. All rights reserved.</p>
        <ul className="footer-links">
          <li><a href="#about">About Us</a></li>
          <li><a href="#contact">Contact</a></li>
          <li><a href="#privacy">Privacy Policy</a></li>
        </ul>
      </footer>
    </div>
    </>
  );
}

export default Home;
