import React, { useEffect, useState } from 'react';
import AdminSidebar from './AdminSidebar';
import Navbarone from './Navbarone';
import { FaBars } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../Functions/config';

const AdminUpdateProfile = ({ sidebarToggle, setSidebarToggle }) => {
    const baseurl=config.baseurl
    const [profile, setProfile] = useState({ username: '', email: '' });
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${baseurl}/admin_profile_view/`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                });
                setProfile(response.data);
            } catch (error) {
                setError('Error fetching profile');
            }
        };

        fetchProfile();
    }, []);

    const handleChange = (e) => {
        setProfile({
            ...profile,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${baseurl}/admin_profile_update/`, profile, {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            });
            navigate('/adminviewprofile');
        } catch (error) {
            setError('Error updating profile');
        } finally {
            setIsLoading(false);
        }
    };

    if (error) return <p>{error}</p>;

    const styles = {
        headerContainer: {
            marginBottom: '20px',
        },
        container: {
            maxWidth: '600px',
            margin: '0 auto',
            padding: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        },
        formGroup: {
            marginBottom: '15px',
        },
        formItem: {
            marginBottom: '10px',
        },
        formValue: {
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            fontSize: '16px',
        },
        buttonGroup: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        btn: {
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
        },
        btnHover: {
            backgroundColor: '#0056b3',
        },
        btnDisabled: {
            backgroundColor: '#ccc',
            cursor: 'not-allowed',
        },
    };

    return (
        <div className="flex h-screen">
            <button
                className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
                onClick={() => setSidebarToggle(!sidebarToggle)}
            >
                <FaBars className="w-6 h-6" />
            </button>

            <AdminSidebar sidebarToggle={sidebarToggle} />

            <div className={`flex-1 transition-all duration-300 ease-in-out ml-0 md:ml-64`}>
                <Navbarone sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

                <main className="p-4">
                    <div style={styles.headerContainer}>
                        <h2>Edit Profile</h2>
                    </div>
                    <div style={styles.container}>
                        <form onSubmit={handleSubmit}>
                            <div style={styles.formGroup}>
                                <div style={styles.formItem}>
                                    <strong>UserName:</strong>
                                    <input
                                        type="text"
                                        name="username"
                                        value={profile.username}
                                        onChange={handleChange}
                                        style={styles.formValue}
                                    />
                                </div>
                            </div>

                            <div style={styles.formGroup}>
                                <div style={styles.formItem}>
                                    <strong>Email:</strong>
                                    <input
                                        type="email"
                                        name="email"
                                        value={profile.email}
                                        onChange={handleChange}
                                        style={styles.formValue}
                                    />
                                </div>
                            </div>

                            <div style={styles.buttonGroup}>
                                <button type="submit" style={styles.btn} disabled={isLoading} 
                                        onMouseOver={(e) => !isLoading && (e.currentTarget.style.backgroundColor = styles.btnHover.backgroundColor)}
                                        onMouseOut={(e) => !isLoading && (e.currentTarget.style.backgroundColor = styles.btn.backgroundColor)}
                                        onClick={(e) => isLoading && (e.preventDefault())}>
                                    {isLoading ? 'Updating...' : 'Update'}
                                </button>
                            </div>
                        </form>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default AdminUpdateProfile;
