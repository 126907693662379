import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import "./Style.css";
import Navbar from './Navbar';
import config from '../../Functions/config';

const Login = () => {
  const baseurl = config.baseurl;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(''); // Reset error state on submit

    try {
      const response = await axios.post(`${baseurl}/api/login/`, {
        username,
        password
      });

      console.log('Login response:', response.data); // Debugging: Log response data

      // Save the token and user data
      localStorage.setItem('token', response.data.token);
      setUser(response.data.user); // Set user data in context

      // Navigate based on user_type
      switch (response.data.user.user_type) {
        case 1:
          navigate('/admin-home');
          break;
        case 2:
          navigate('/seeker-home');
          break;
        case 3:
          navigate('/employee-home');
          break;
        default:
          setError('Unknown user type');
          break;
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Invalid username or password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className='page'>
        <form className='cover' onSubmit={handleSubmit}>
          <h2>Login</h2>
          <input
            type="text"
            placeholder="Username"
            className='login-input'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            className='login-input'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button 
            type="submit" 
            className={`w-full py-2 rounded-lg transition-colors duration-300 ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-custom-blue'} text-white`} 
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Login'}
          </button>
          {error && <p className="text-red-500 text-center mt-2">{error}</p>}
        </form>
      </div>
    </>
  );
};

export default Login;
