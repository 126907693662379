import React from 'react';
import { FaBars, FaBell, FaUserCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const Navbartwo= ({ sidebarToggle, setSidebarToggle }) => {
  return (
    <nav className='bg-custom-blue px-4 py-3 flex justify-between items-center w-100'>
      <div className='flex items-center text-xl'>
        {/* <FaBars
          className='text-white me-4 cursor-pointer md:hidden'
          onClick={() => setSidebarToggle(!sidebarToggle)}
        /> */}
        <span className='text-white font-semibold'>JobPortal</span>
      </div>
      <div className='flex items-center gap-x-5'>
        <div className='relative md:w-65'></div>
        <div className='text-white'>
  <Link to="/seeker_notification"> {/* Change to your actual route */}
    <FaBell className='w-6 h-6' />
  </Link>
</div>
        <div className='relative'>
          <button className='text-white'>
            {/* <FaUserCircle className='w-6 h-6 mt-1' /> */}
            <div className='z-10 hidden bg-white absolute rounded-lg shadow w-32 group-focus:block top-full right-0'>
              <ul className='py-2 text-sm text-gray-950'>
                <li>
                  <Link to='/adminviewprofile' className='link'>
                    Profile
                  </Link>
                </li>
              </ul>
            </div>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbartwo;
