import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaBars } from 'react-icons/fa';
import SeekerSidebar from './SeekerSidebar';
import Navbartwo from './Navbartwo';
import config from '../../Functions/config';

const Seeker_home = ({ sidebarToggle, setSidebarToggle }) => {
    const baseurl = config.baseurl;
    const [profileComplete, setProfileComplete] = useState(null);
    const [loading, setLoading] = useState(true);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const fetchJobs = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${baseurl}/api/jobs/`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                console.log(response.data);
                console.log('Jobs fetched:', response.data.jobs);
                const currentDate = new Date().toISOString().split('T')[0];
                const validJobs = response.data.filter(job => job.last_date >= currentDate && job.status === 'APPROVED');
                console.log(validJobs);
                setJobs(validJobs);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        };

        const checkProfileCompletion = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${baseurl}/api/profile-completion/`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                setProfileComplete(response.data.profile_complete);
                if (!response.data.profile_complete) {
                    setNotificationMessage('Your profile is incomplete! Please complete your profile.');
                }
            } catch (error) {
                console.error('Error fetching profile completion status:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchJobs();
        checkProfileCompletion();
    }, []);

    const handleCloseNotification = () => {
        setNotificationMessage('');
    };

    if (loading) return <div>Loading...</div>;

    return (
        <div className="flex h-screen">
            {/* Sidebar Toggle Button (Visible on mobile) */}
            <button
                className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
                onClick={() => setSidebarToggle(!sidebarToggle)}
            >
                <FaBars className="w-6 h-6" />
            </button>

            {/* Sidebar */}
            <SeekerSidebar sidebarToggle={sidebarToggle} />

            {/* Main Content Area */}
            <div className={`flex-1 transition-all duration-300 ease-in-out ${sidebarToggle ? 'ml-64' : 'ml-0'} md:ml-64`}>
                {/* Navbar */}
                <Navbartwo sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

                {/* Main content */}
                <main className="p-4">
                    {/* Notification Message */}
                    {notificationMessage && (
                        <div className="notification-bar">
                            <span>{notificationMessage}</span>
                            <button onClick={handleCloseNotification}>Close</button>
                        </div>
                    )}
                  

                    {/* Jobs List */}
                    <div className="jobs-list mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {jobs.length === 0 ? (
                            <p>No jobs available at the moment.</p>
                        ) : (
                            jobs.map(job => (
                                <div key={job.id} className="job-card1 p-4 border rounded shadow mb-4 flex flex-col">
                                    <img src={job.image || 'default-image-url.jpg'} alt={job.designation} className="h-32 w-full object-cover rounded mb-4" />
                                    <h2 className="text-lg font-semibold">{job.designation}</h2>
                                    <p>{job.description}</p>
                                    <p>Requirements:{job.requirements}</p>
                                    <p className="text-gray-600">Company: {job.employee?.company || 'N/A'}</p>
                                    <p className="text-gray-600">Location: {job.location || 'N/A'}</p>
                                    <p className="text-gray-600">Last Date: {new Date(job.last_date).toLocaleDateString()}</p>
                                    {/* <button className="mt-2 bg-blue-500 text-white py-2 px-4 rounded">
                                        Apply
                                    </button> */}
                                </div>
                            ))
                        )}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Seeker_home;
