import { useEffect, useState } from "react";
import axios from 'axios';
import { FaBars } from 'react-icons/fa';
import EmployeeSidebar from './EmployeeSidebar';
import Navbarthree from './Navbarthree';
import config from "../../Functions/config";

const EmployeeHome = ({ sidebarToggle, setSidebarToggle }) => {
  const baseurl = config.baseurl;
  const [postedJobsCount, setPostedJobsCount] = useState(null);
  const [totalJobs, setTotalJobs] = useState(null);
  const [totalApplications, setTotalApplications] = useState(null);
  const [jobsData, setJobsData] = useState([]);
  const [recentJobs, setRecentJobs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('No authentication token found.');
      return;
    }

    const fetchJobData = async () => {
      try {
        const dashboardResponse = await axios.get(`${baseurl}/dashboard/`, {
          headers: {
            'Authorization': `Token ${token}`,
          },
        });

        setPostedJobsCount(dashboardResponse.data.posted_jobs_count);
        setTotalJobs(dashboardResponse.data.total_jobs);
        setTotalApplications(dashboardResponse.data.total_applications);
        setJobsData(dashboardResponse.data.jobs_data);

        const recentJobsResponse = await axios.get(`${baseurl}/jobs/recent_jobs/`, {
          headers: {
            'Authorization': `Token ${token}`,
          },
        });
        setRecentJobs(recentJobsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data. Please try again.');
      }
    };

    fetchJobData();
  }, []);

  const currentDate = new Date();

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <button
        className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
        onClick={() => setSidebarToggle(!sidebarToggle)}
      >
        <FaBars className="w-6 h-6" />
      </button>

      <EmployeeSidebar sidebarToggle={sidebarToggle} />

      <div className={`flex-1 transition-all duration-300 ease-in-out ${sidebarToggle ? 'ml-64' : 'ml-0'} md:ml-64`}>
        <Navbarthree sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

        <main className="p-6 bg-gray-50 min-h-full">
  <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Welcome to Your Dashboard</h1>
  
  {error && <div className="text-red-600 text-center mb-4">{error}</div>}

  {postedJobsCount === null || totalJobs === null || totalApplications === null ? (
    <div className="text-center text-lg">Loading data...</div>
  ) : (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
      <div className="bg-blue-200 border border-blue-400 rounded-lg p-6 shadow-md text-center">
        <h2 className="text-xl font-semibold">Total Jobs</h2>
        <p className="text-3xl font-bold text-blue-800">{totalJobs}</p>
      </div>
      <div className="bg-green-200 border border-green-400 rounded-lg p-6 shadow-md text-center">
        <h2 className="text-xl font-semibold">Total Applications</h2>
        <p className="text-3xl font-bold text-green-800">{totalApplications}</p>
      </div>
      <div className="bg-yellow-200 border border-yellow-400 rounded-lg p-6 shadow-md text-center">
        <h2 className="text-xl font-semibold">Approved Jobs</h2>
        <p className="text-3xl font-bold text-yellow-800">{postedJobsCount}</p>
      </div>
    </div>
  )}

  {/* Job Information Section */}
  <section className="mb-6">
    <h2 className="text-2xl font-semibold mb-4">Your Job Schedule Information</h2>
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-4 text-left text-gray-600">Designation</th>
            <th className="px-6 py-4 text-left text-gray-600">Post Date</th>
            <th className="px-6 py-4 text-left text-gray-600">Last Date</th>
            <th className="px-6 py-4 text-left text-gray-600">Applicant Count</th>
          </tr>
        </thead>
        <tbody>
          {jobsData.map(job => (
            new Date(job.last_date) >= currentDate && (
              <tr key={job.job_id} className="hover:bg-gray-100">
                <td className="px-6 py-4">{job.designation}</td>
                <td className="px-6 py-4">{job.posting_date}</td>
                <td className="px-6 py-4">{job.last_date}</td>
                <td className="px-6 py-4">{job.applicant_count}</td>
              </tr>
            )
          ))}
        </tbody>
      </table>
    </div>
  </section>

  {/* Recent Jobs Section */}
  <section>
    <h2 className="text-2xl font-semibold mb-4">Recent Jobs Posted This Week</h2>
    {recentJobs.length === 0 ? (
      <div className="text-center text-lg">No recent jobs posted this week.</div>
    ) : (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {recentJobs
          .filter(job => new Date(job.last_date) >= currentDate)
          .map(job => (
            <div key={job.id} className="bg-white border border-gray-200 rounded-lg shadow-sm p-4">
              <h3 className="font-semibold text-lg">{job.designation}</h3>
              <p className="text-gray-600"><strong>Posted on:</strong> {job.posting_date}</p>
              <p className="text-gray-600"><strong>Last Date:</strong> {job.last_date}</p>
            </div>
          ))}
      </div>
    )}
  </section>
</main>

      </div>
    </div>
  );
};

export default EmployeeHome;
