import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import config from '../../Functions/config';

const AdminSidebar = ({ sidebarToggle }) => {
  const baseurl=config.baseurl
  const [counts, setCounts] = useState({
    newSeekers: 0,
    newEmployees: 0,
    newJobs: 0,
  });

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await fetch(`${baseurl}/api/admin-dashboard/counts/`); // Adjust URL if necessary
        const data = await response.json();
        setCounts({
          newSeekers: data.new_seekers,
          newEmployees: data.new_employees,
          newJobs: data.new_jobs,
        });
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };

    fetchCounts();
  }, []);

  // Inline style for the badge
  const badgeStyle = {
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '9999px',
    padding: '0.2em 0.5em',
    marginLeft: '0.5em',
    fontSize: '0.8em',
  };

  return (
    <div
      className={`fixed top-0 left-0 h-full w-64 bg-custom-blue px-4 py-2 transition-transform transform ${
        sidebarToggle ? "translate-x-0" : "-translate-x-full"
      } md:translate-x-0 md:block md:w-64`}
    >
      <div>
        <h1 className='text-2xl text-white font-bold'>Admin Dashboard</h1>
      </div>
      <hr className='my-2' />
      <ul className='space-y-2'>
        <li><Link to='/admin-home' className='link'>Home</Link></li>
        <li><Link to='/adminviewprofile' className='link'>Profile</Link></li>
        <li>
          <Link to='/approve_seeker' className='link'>
            New Seekers <span style={badgeStyle}>{counts.newSeekers}</span>
          </Link>
        </li>
        <li>
          <Link to='/admin_view_seeker' className='link'>View Seekers</Link>
        </li>
        <li>
          <Link to='/approve_employee' className='link'>
            New Employee <span style={badgeStyle}>{counts.newEmployees}</span>
          </Link>
        </li>
        <li>
          <Link to='/admin_view_employe' className='link'>Employees</Link>
        </li>
        <li>
          <Link to='/admin_approve_jobs' className='link'>
            New Jobs <span style={badgeStyle}>{counts.newJobs}</span>
          </Link>
        </li>
        <li>
          <Link to='/admin_view_job' className='link'>All Jobs</Link>
        </li>
        <li>
          <Link 
            to='/' 
            className='flex items-center text-red-500 font-bold hover:bg-red-100 rounded-md p-2 transition duration-200'
          >
            <FaSignOutAlt className="mr-2" /> 
            LogOut
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AdminSidebar;
