import React from 'react';
import AdminSidebar from './AdminSidebar';
import Navbarone from './Navbarone';
import { FaBars } from 'react-icons/fa';

const Admin = ({ sidebarToggle, setSidebarToggle }) => {
  return (
    <div className="flex h-screen">
      {/* Sidebar Toggle Button (Visible on mobile) */}
      <button
        className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
        onClick={() => setSidebarToggle(!sidebarToggle)}
      >
        <FaBars className="w-6 h-6" />
      </button>

      {/* Sidebar */}
      <AdminSidebar sidebarToggle={sidebarToggle} />

      {/* Main Content Area */}
      <div className={`flex-1 transition-all duration-300 ease-in-out ml-0 md:ml-64`}>
        {/* Navbar */}
        <Navbarone sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

        {/* Main content */}
        <main className="p-4">
          {/* Content goes here */} <center><h2>Welcome Admin </h2></center>
        </main>
      </div>
    </div>
  );
};

export default Admin;
