import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaBars } from 'react-icons/fa';
import SeekerSidebar from './SeekerSidebar';
import Navbartwo from './Navbartwo';
import './Style.css';
import config from '../../Functions/config';

const ProfileIncompleteDialog = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg">
                <h2 className="text-lg font-semibold">Profile Incomplete</h2>
                <p className='text-danger'>Please complete your profile information before applying for jobs.</p>
                <button className="mt-4 bg-blue-500 text-white py-2 px-4 rounded" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );
};

const SearchJob = ({ sidebarToggle, setSidebarToggle }) => {
    const baseurl = config.baseurl;
    const [jobs, setJobs] = useState([]);
    const [filters, setFilters] = useState({ designation: '', location: '', last_date: '', jobtype: '' });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [seeker, setSeeker] = useState(null);
    const [loadingJobs, setLoadingJobs] = useState(false);
    const [loadingSeeker, setLoadingSeeker] = useState(false);
    const [error, setError] = useState('');
    const [loadingApply, setLoadingApply] = useState(false);
    const [appliedJobs, setAppliedJobs] = useState(new Set());
    const [selectedJob, setSelectedJob] = useState(null);

    const fetchJobs = async () => {
        setLoadingJobs(true);
        setError('');
        try {
            const response = await axios.get(`${baseurl}/api/sjob/`, { params: filters });
            setJobs(response.data);
        } catch (error) {
            setError('Error fetching jobs.');
            console.error('Error fetching jobs:', error);
        } finally {
            setLoadingJobs(false);
        }
    };

    const fetchSeekerInfo = async () => {
        setLoadingSeeker(true);
        setError('');
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${baseurl}/api/seeker_view`, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            setSeeker(response.data);
        } catch (error) {
            setError('Error fetching seeker information.');
            console.error('Error fetching seeker information:', error);
        } finally {
            setLoadingSeeker(false);
        }
    };

    useEffect(() => {
        fetchSeekerInfo();
        fetchJobs();
    }, []);

    const handleChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const applyForJob = async (jobId) => {
        const token = localStorage.getItem('token');
        setLoadingApply(true);
        try {
            await axios.post(`${baseurl}/api/apply/${jobId}/`, {}, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            alert(`Applied successfully`);
            setAppliedJobs(prev => new Set(prev).add(jobId));
        } catch (error) {
            console.error('Error applying for job:', error);
            if (error.response && error.response.data) {
                alert(error.response.data.error || 'Failed to apply for job. Please try again.');
            } else {
                alert('Failed to apply for job. Please try again.');
            }
        } finally {
            setLoadingApply(false);
        }
    };

    const handleApply = (jobId) => {
        if (!isProfileComplete(seeker)) {
            setIsDialogOpen(true);
        } else {
            applyForJob(jobId);
        }
    };

    const handleReadMore = (job) => {
        setSelectedJob(job);
    };

    const closeModal = () => {
        setSelectedJob(null);
    };

    const isProfileComplete = (seeker) => {
        if (!seeker) return false;

        const { name, date_of_birth, mobile_number, address } = seeker;
        return name && date_of_birth && mobile_number && address;
    };

    const approvedJobs = jobs.filter(job => job.status === 'APPROVED');

    return (
        <div className="flex flex-col h-screen md:flex-row">
            <button
                className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
                onClick={() => setSidebarToggle(!sidebarToggle)}
            >
                <FaBars className="w-6 h-6" />
            </button>

            <SeekerSidebar sidebarToggle={sidebarToggle} />

            <div className={`flex-1 transition-all duration-300 ease-in-out ${sidebarToggle ? 'ml-64' : 'ml-0'} md:ml-64`}>
                <Navbartwo sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

                <main className="p-4">
                    <h1 className="text-2xl font-bold mb-6 text-gray-800">Search Jobs</h1>

                    {loadingJobs && <p>Loading jobs...</p>}
                    {loadingSeeker && <p>Loading seeker information...</p>}
                    {error && <p className="text-red-500">{error}</p>}

                    <div className="flex flex-col md:flex-row items-center mb-4 space-y-2 md:space-y-0 md:space-x-2">
                        <input
                            type="text"
                            name="designation"
                            placeholder="Designation"
                            value={filters.designation}
                            onChange={handleChange}
                            className="border border-gray-300 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 flex-grow"
                        />
                        <input
                            type="text"
                            name="jobtype"
                            placeholder="Job Type"
                            value={filters.jobtype}
                            onChange={handleChange}
                            className="border border-gray-300 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 flex-grow"
                        />
                        <input
                            type="text"
                            name="location"
                            placeholder="Location"
                            value={filters.location}
                            onChange={handleChange}
                            className="border border-gray-300 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 flex-grow"
                        />
                        <input
                            type="date"
                            name="last_date"
                            value={filters.last_date}
                            onChange={handleChange}
                            className="border border-gray-300 p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 flex-grow"
                        />
                        <button
                            onClick={fetchJobs}
                            className="bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700 transition duration-200"
                        >
                            Search
                        </button>
                    </div>

                    <h2 className="text-lg font-semibold mb-4">Jobs</h2>
                    {approvedJobs.length === 0 ? (
                        <p>No jobs available.</p>
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {approvedJobs.map(job => (
                                <div key={job.id} className="border p-4 rounded-md shadow-md hover:shadow-lg transition-shadow">
                                    <h2 className="font-semibold text-lg text-gray-900 flex items-center">
                                        {job.designation}
                                        {job.employee && job.employee.user_status === 2 && (
                                            <span className="ml-2 text-green-500">&#10003;</span> // Green tick mark
                                        )}
                                    </h2>
                                    <p className="text-gray-700">{job.location}</p>
                                    <p className="text-gray-600">Last Date: {job.last_date}</p>
                                    <p className="text-gray-600">Job Type: {job.jobtype}</p>
                                    <div className="flex justify-between mt-4">
                                        <button
                                            onClick={() => handleReadMore(job)}
                                            className="bg-blue-500 text-white py-1 px-3 rounded-md hover:bg-blue-600"
                                        >
                                            Read More
                                        </button>
                                        <button
                                            onClick={() => handleApply(job.id)}
                                            className={`bg-green-500 text-white py-1 px-3 rounded-md hover:bg-green-600 ${loadingApply || appliedJobs.has(job.id) ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            disabled={loadingApply || appliedJobs.has(job.id)}
                                        >
                                            {appliedJobs.has(job.id) ? 'Applied' : 'Apply'}
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {selectedJob && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full">
                                <h2 className="text-lg font-semibold">{selectedJob.designation}</h2>
                                <img src={selectedJob.image} alt={`${selectedJob.company_name} logo`} className="w-16 h-16 mb-4" />
                                <p className="mt-2">{selectedJob.description}</p>
                                <h3 className="mt-4 font-semibold">Requirements:</h3>
                                <p>{selectedJob.requirements}</p>
                                <h3 className="mt-4 font-semibold">Company:</h3>
                                <p>{selectedJob.company}</p>
                                <button
                                    className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
                                    onClick={closeModal}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    )}

                    <ProfileIncompleteDialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
                </main>
            </div>
        </div>
    );
};

export default SearchJob;
