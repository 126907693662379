import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaBars } from 'react-icons/fa';
import SeekerSidebar from './SeekerSidebar';
import Navbartwo from './Navbartwo';
import config from '../../Functions/config';

const SeekerViewAppliedJob = ({ sidebarToggle, setSidebarToggle }) => {
  const baseurl = config.baseurl;
  const [notifications, setNotifications] = useState([]);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(''); 
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApplications = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${baseurl}/seeker/applications?status=${filter}`, {
          headers: {
            'Authorization': `Token ${token}`,
          },
        });
        setApplications(response.data);
      } catch (error) {
        console.error('Error fetching applications:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, [filter]);

  const handleFilterChange = (status) => {
    setFilter(status);
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${baseurl}/api/seeker_notifications`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setNotifications(response.data);
      } catch (err) {
        setError('Failed to load notifications.');
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const markAsRead = async (id) => {
    const token = localStorage.getItem('token');
    try {
      await axios.patch(`${baseurl}/notifications/${id}/mark_as_read/`, {}, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setNotifications(prevNotifications =>
        prevNotifications.map(notification =>
          notification.id === id ? { ...notification, is_read: true } : notification
        )
      );
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="flex flex-col h-screen md:flex-row">
      <button
        className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
        onClick={() => setSidebarToggle(!sidebarToggle)}
      >
        <FaBars className="w-6 h-6" />
      </button>

      <SeekerSidebar sidebarToggle={sidebarToggle} />

      <div className={`flex-1 transition-all duration-300 ease-in-out ${sidebarToggle ? 'ml-64' : 'ml-0'} md:ml-64`}>
        <Navbartwo sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

        <main className="p-4  bg-gray-100">
          <h1 className="text-2xl font-bold mb-4">Notifications</h1>

          {loading ? (
            <p>Loading notifications...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : notifications.length === 0 ? (
            <p>No notifications found.</p>
          ) : (
            <ul className="space-y-2">
              {notifications.map(notification => (
                <li
                  key={notification.id}
                  className={`p-4 border rounded bg-white ${notification.is_read ? 'opacity-50' : 'font-bold'}`}
                  onClick={() => !notification.is_read && markAsRead(notification.id)}
                >
                  <p>{notification.message}</p>
                  <span className="text-sm text-gray-500">{new Date(notification.created_at).toLocaleString()}</span>
                </li>
              ))}
            </ul>
          )}

          <h2 className="text-3xl font-bold mb-4">My Applied Jobs</h2>
          
          <div className="flex flex-wrap gap-2 mb-4">
            <button onClick={() => handleFilterChange('')} className="filter-btn">All</button>
            <button onClick={() => handleFilterChange('accepted')} className="filter-btn">Accepted</button>
            <button onClick={() => handleFilterChange('rejected')} className="filter-btn">Rejected</button>
            <button onClick={() => handleFilterChange('Viewed')} className="filter-btn">Profile Visited</button>
          </div>

          <div className="applications-list">
            {applications.length === 0 ? (
              <p>No applications found.</p>
            ) : (
              applications.map(app => (
                <div key={app.id} className="application-card">
                  <h3 className="job-title">{app.job.designation}</h3>
                  {app.job.image && (
                    <img
                      src={`${baseurl}${app.job.image}`}
                      alt={app.job.designation}
                      className="job-image"
                    />
                  )}
                  <p className="company-name">{app.job.company}</p>
                  <p className="job-description">{app.job.description}</p>
                  <p className="job-requirements"><strong>Requirements:</strong> {app.job.requirements}</p>
                  <p className="application-status">Status: <strong>{app.applied_status}</strong></p>
                  <p className="application-date">Applied On: {new Date(app.applied_on).toLocaleDateString()}</p>
                </div>
              ))
            )}
          </div>
        </main>
      </div>

      <style>{`
        .filter-btn {
          background-color: #4a90e2;
          color: white;
          padding: 10px 15px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s;
          flex: 1 1 auto;
        }

        .filter-btn:hover {
          background-color: #357ab8;
        }

        .applications-list {
          display: flex;
          flex-wrap: wrap; /* Allow wrapping for multiple cards */
          gap: 20px; /* Space between cards */
        }

        .application-card {
          background-color: #ffffff;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: transform 0.2s, box-shadow 0.3s;
          flex: 0 0 calc(50% - 10px); /* Two cards per row */
          margin-bottom: 20px; /* Space below each card */
        }

        .application-card:hover {
          transform: translateY(-2px);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        }

        .job-title {
          margin: 0;
          font-size: 1.5rem;
          color: #333;
          font-weight: 600;
        }

        .job-image {
          width: 50%;
          height: auto;
          border-radius: 8px;
          margin: 10px 0;
        }

        .company-name {
          font-size: 1.2rem;
          color: #555;
        }

        .job-description {
          color: #666;
          margin: 10px 0;
        }

        .job-requirements {
          color: #666;
          margin: 5px 0;
        }

        .application-status,
        .application-date {
          color: #777;
          font-size: 0.9rem;
        }

        @media (max-width: 768px) {
          .filter-btn {
            flex: 1 1 45%;
          }

          .application-card {
            flex: 0 0 100%; /* Full width on smaller screens */
          }
        }
      `}</style>
    </div>
  );
};

export default SeekerViewAppliedJob;
