import React, { useState } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import config from '../../Functions/config';

const Employeereg = () => {
    const baseurl=config.baseurl
    const [formData, setFormData] = useState({
        username: '',
        companyName: '',
        email: '',
        address: '',
        mobileNumber: '',
        website: '',
        logo: null
    });
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: files ? files[0] : value
        }));
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validateMobileNumber = (mobile) => {
        const re = /^[0-9]{10}$/; // Adjust regex to fit your mobile number format
        return re.test(mobile);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null); // Clear previous errors

        // Validate email
        if (!validateEmail(formData.email)) {
            setError('Invalid email format.');
            return;
        }

        // Validate mobile number
        if (!validateMobileNumber(formData.mobileNumber)) {
            setError('Mobile number must be 10 digits long.');
            return;
        }

        try {
            const response = await axios.post(`${baseurl}/employee_reg/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            alert('Registration successful! Wait for admin approval');
            setFormData({
                username: '',
                email: '',
                companyName: '',
                mobileNumber: '',
                address: '',
                website: '',
                logo: null 
            });
            setError(null);
        } catch (error) {
            if (error.response && error.response.data) {
                // Check for specific error messages
                if (error.response.data.error) {
                    setError(error.response.data.error);
                } else if (error.response.data.username) {
                    setError('Username already exists.');
                } else if (error.response.data.email) {
                    setError('Email already exists.');
                } else {
                    setError('Registration failed: ' + JSON.stringify(error.response.data));
                }
            } else {
                setError('Registration failed: ' + error.message);
            }
        }
    };

    return (
        <>
            <Navbar />
            <div className="registration-form">

                <h2>Employee Registration</h2>
                {error && <p className="error">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="companyName">Company Name:</label>
                    <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="website">Website:</label>
                    <input
                        type="text"
                        id="website"
                        name="website"
                        value={formData.website}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="mobileNumber">Mobile Number:</label>
                    <input
                        type="text"
                        id="mobileNumber"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="logo">Logo:</label>
                    <input
                        type="file"
                        id="logo"
                        name="logo"
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="address">Address:</label>
                    <textarea
                        name="address"
                        id="address"
                        onChange={handleChange}
                        value={formData.address}
                        required
                    />
                   
                    <div className="button-container">
                        <button type="submit">Register</button>
                        <button type="button" onClick={() => setFormData({
                            username: '',
                            email: '',
                            companyName: '',
                            mobileNumber: '',
                            address: '',
                            website: '',
                            logo: null
                        })}>
                            Clear
                        </button>
                        <button type="button" onClick={() => window.location.href = '/'}>
                            Back to Home
                        </button>
                    </div>
                </form>
            </div>

            <style jsx>{`
                .registration-form {
                    max-width: 800px;
                    margin: 50px auto;
                    padding: 20px;
                    border-radius: 8px;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                    background-color: #fff;
                }

                .registration-form h2 {
                    text-align: center;
                    margin-bottom: 20px;
                    color: rgb(4, 4, 80);
                }

                .registration-form label {
                    display: block;
                    margin: 10px 0 5px;
                    font-weight: 600;
                }

                .registration-form input,
                .registration-form textarea {
                    width: 100%;
                    padding: 10px;
                    margin-bottom: 15px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    font-size: 1rem;
                }

                .registration-form input:focus,
                .registration-form textarea:focus {
                    border-color: rgb(4, 4, 80);
                    outline: none;
                }

                .registration-form .error {
                    color: red;
                    text-align: center;
                    margin-bottom: 10px;
                }

                .button-container {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;
                }

                .button-container button {
                    flex: 1;
                    padding: 10px;
                    margin: 0 5px;
                    border: none;
                    border-radius: 4px;
                    font-size: 1rem;
                    color: white;
                    background-color: rgb(4, 4, 80);
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                }

                .button-container button:hover {
                    background-color: white;
                    color: rgb(4, 4, 80);
                    border: 1px solid rgb(4, 4, 80);
                }
            `}</style>
        </>
    );
};

export default Employeereg;
