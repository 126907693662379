import React, { useState } from 'react';
import axios from 'axios';
import { FaBars } from 'react-icons/fa';
import SeekerSidebar from './SeekerSidebar';
import Navbartwo from './Navbartwo';
import { useNavigate } from 'react-router-dom';
import './Style.css';
import config from '../../Functions/config';

const CompleteProfile = ({ sidebarToggle, setSidebarToggle }) => {
    const baseurl=config.baseurl
    const [formData, setFormData] = useState({
        education_details: '', // Store as a single string
        address: '',
        profile_picture: null,
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('education_details', formData.education_details);
        formDataToSend.append('address', formData.address);
        if (formData.profile_picture) {
            formDataToSend.append('profile_picture', formData.profile_picture);
        }

        const token = localStorage.getItem('token');
        try {
            await axios.put(`${baseurl}/api/update_seeker_info/`, formDataToSend, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            navigate('/seeker_profile'); // Redirect back to the profile page
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    return (
        <div className="flex h-screen bg-gray-100">
            <button
                className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
                onClick={() => setSidebarToggle(!sidebarToggle)}
            >
                <FaBars className="w-6 h-6" />
            </button>

            <SeekerSidebar sidebarToggle={sidebarToggle} />

            <div className={`flex-1 transition-all duration-300 ease-in-out ${sidebarToggle ? 'ml-64' : 'ml-0'} md:ml-64`}>
                <Navbartwo sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

                <main className="p-4  bg-gray-100">
                    <div className="p-4">
                        <h1 className="text-2xl font-bold">Complete Profile</h1>
                        <form onSubmit={handleSubmit} className="mt-4">
                            <div className="mt-4">
                                <label>Education Info:</label>
                                <textarea
                                    name="education_details"
                                    value={formData.education_details}
                                    onChange={handleChange}
                                    className="border border-gray-300 p-2 rounded-md w-full"
                                    placeholder="Enter education info (one per line)"
                                    rows="5" // Adjust rows as needed
                                />
                            </div>
                            
                            <div className="mt-4">
                                <label>Address:</label>
                                <textarea
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    className="border border-gray-300 p-2 rounded-md w-full"
                                />
                            </div>
                            <div className="mt-4">
                                <label>Profile Picture:</label>
                                <input
                                    type="file"
                                    name="profile_picture"
                                    onChange={handleChange}
                                    className="border border-gray-300 p-2 rounded-md w-full"
                                />
                            </div>
                            <button type="submit" className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">
                                Save
                            </button>
                        </form>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default CompleteProfile;
