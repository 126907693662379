import React, { useEffect, useState } from 'react';
import AdminSidebar from './AdminSidebar';
import Navbarone from './Navbarone';
import { FaBars } from 'react-icons/fa';
import axios from 'axios';
import './table.css';
import config from '../../Functions/config';

function AdminViewEmployess({ sidebarToggle, setSidebarToggle }) {
    const baseurl=config.baseurl
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        (async () => await loadEmployees())();
    }, []);

    async function loadEmployees() {
        try {
            const result = await axios.get(`${baseurl}/admin_view_employess`);
            setEmployees(Array.isArray(result.data) ? result.data : []);
        } catch (err) {
            console.error('Error fetching employees:', err.response ? err.response.data : err.message);
            setError('Error fetching employee data');
        } finally {
            setLoading(false);
        }
    }

    const handleVerify = async (employeeId) => {
        try {
            const response = await axios.put(`${baseurl}/verify_employee/${employeeId}/`);
            const updatedEmployee = response.data;

            // Update the employee list to reflect the new status
            setEmployees(employees.map(emp => emp.id === employeeId ? updatedEmployee : emp));
        } catch (err) {
            console.error('Error verifying employee:', err.response ? err.response.data : err.message);
            setError('Error verifying employee');
        }
    };

    const deleteEmployee = async (empid) => {
        try {
            const response = await axios.delete(`${baseurl}/delete_employee/${empid}/`);
            if (response.status === 204) {
                alert("Employee blocked successfully");
                setEmployees(employees.filter(emp => emp.id !== empid));
            } else {
                alert("Error deleting employee");
            }
        } catch (error) {
            console.error('Error deleting employee:', error.response ? error.response.data : error.message);
            setError('Error deleting employee');
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="flex h-screen">
            <button
                className="md:hidden fixed top-4 left-4 z-50 text-white bg-custom-blue p-2 rounded-full"
                onClick={() => setSidebarToggle(!sidebarToggle)}
            >
                <FaBars className="w-6 h-6" />
            </button>

            <AdminSidebar sidebarToggle={sidebarToggle} />

            <div className={`flex-1 transition-all duration-300 ease-in-out ml-0 md:ml-64`}>
                <Navbarone sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />

                <main style={styles.main} className='pt-4'>
                    <div style={styles.headerContainer}>
                        <h2 style={styles.headerTitle}>Employee Details</h2>
                    </div>
                    <div className='cards-container'>
                        {employees.map(emp => (
                            <div key={emp.id} style={styles.card}>
                                <div style={styles.logoContainer}>
                                    {emp.logo ? (
                                        <img 
                                            src={`${baseurl}${emp.logo}`} 
                                            alt={`${emp.username}'s logo`} 
                                            style={styles.logo}
                                        />
                                    ) : (
                                        <div style={styles.logoPlaceholder} />
                                    )}
                                </div>
                                <h3>{emp.username}</h3>
                                <p>Email: {emp.email}</p>
                                <p>Company: {emp.company}</p>
                                <p>Address: {emp.address}</p>
                                <p>Mobile: {emp.mobile_number}</p>
                                <p>Website: {emp.website}</p>
                                <p style={emp.user_status === 2 ? styles.verified : styles.notVerified}>
                                    {emp.user_status === 2 ? 'Verified' : 'Not Verified'}
                                </p>
                                <div style={styles.buttonContainer}>
                                    {emp.user_status !== 2 && (
                                        <button
                                            style={styles.successButton}
                                            onClick={() => handleVerify(emp.id)}
                                        >
                                            Verify
                                        </button>
                                    )}
                                    <button style={styles.dangerButton} onClick={() => deleteEmployee(emp.id)}>
                                        Block
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </main>
            </div>
        </div>
    );
}

const styles = {
    main: {
        padding: '16px',
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        marginTop: '20px',
        overflow: 'auto',
    },
    headerContainer: {
        textAlign: 'center',
        marginBottom: '20px',
    },
    headerTitle: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#333',
    },
    cardsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: '20px',
    },
    card: {
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '16px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
    },
    logoContainer: {
        marginBottom: '10px',
    },
    logo: {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
    },
    logoPlaceholder: {
        width: '50px',
        height: '50px',
        backgroundColor: '#ccc',
        borderRadius: '50%',
    },
    verified: {
        color: 'green',
    },
    notVerified: {
        color: 'red',
    },
    buttonContainer: {
        marginTop: '10px',
    },
    successButton: {
        padding: '8px 12px',
        backgroundColor: '#28a745',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginRight: '8px',
    },
    dangerButton: {
        padding: '8px 12px',
        backgroundColor: '#dc3545',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};

export default AdminViewEmployess;
